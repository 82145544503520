import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type ReactElement } from 'react'
import { type TopicInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'
import { ContentstackTopicRow } from './ContentstackTopicRow'

interface ContentstackTopicProps {
  data?: TopicInfoFragment | null
}

/**
 * Component for rendering a Topic created in Contentstack
 */
function ContentstackTopic ({ data }: ContentstackTopicProps): ReactElement | null {
  const {
    title,
    appearance,
    targetingConnection
  } = data ?? {}

  const {
    layout
  } = appearance ?? {}

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', 'y', appearance?.spacing_y)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || data == null) {
    return null
  }

  const sectionClassNames = clsx(
    'mw-xxxl m-auto',
    background.className
  )

  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className
  )

  const cardClassNames = clsx(
    'border rounded-3 px-4 py-6 py-md-8',
    background.className,
    spacing?.className,
    layout === 'card' && 'px-md-6'
  )

  if (layout === 'card') {
    return (
      <section
        id={contentstackTitleToHtmlId(title)}
        data-gtm-click-section='topic'
        data-gtm-click-subsection1={title}
      >
        <div className='container-xxxl mw-xxl px-4 py-6 py-md-8'>
          <div className={cardClassNames}>
            <ContentstackTopicRow data={data} />
            {background.styles}
            {spacing?.styles}
          </div>
        </div>
      </section>
    )
  }

  return (
    <section
      className={sectionClassNames}
      id={contentstackTitleToHtmlId(title)}
      data-gtm-click-section='topic'
      data-gtm-click-subsection1={title}
    >
      <div className={containerClassNames}>
        <ContentstackTopicRow data={data} />
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackTopic
}
