import { ContentstackMediaCallout } from './ContentstackMediaCallout'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type MediaCalloutGroupInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'

interface ContentstackMediaCalloutGroupProps {
  data: MediaCalloutGroupInfoFragment | null
}

/**
 * The Media Callout Group is a collection of up to three individual Media Callouts, each containing an image, heading, rich text, and link.
 */
function ContentstackMediaCalloutGroup ({ data }: ContentstackMediaCalloutGroupProps): ReactElement | null {
  const {
    media_callouts: mediaCallouts,
    targetingConnection,
    title
  } = data ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isGroupEmpty = isArrayEmpty(mediaCallouts)
  if (!showContent || isGroupEmpty) {
    return null
  }

  return (
    <section className='container-xxxl mw-xxl px-4 py-8' id={contentstackTitleToHtmlId(title)}>
      <div className='row justify-content-evenly gy-5'>
        {mediaCallouts?.map((mediaCallout, index) => (
          <ContentstackMediaCallout
            mediaCallout={mediaCallout}
            key={index}
            includeBottomBorder={index !== mediaCallouts.length - 1}
          />
        ))}
      </div>
    </section>
  )
}

export {
  ContentstackMediaCalloutGroup
}
