import { type ReactElement, useState } from 'react'
import { ContentstackShoppableDotPopup } from './ContentstackShoppableDotPopup'
import { type ShoppableImageDot } from '@stuller/contentstack/util/types'
import { type ProductData } from '@stuller/stullercom/ui'

interface ContentstackShoppableDotProps {
  data?: ShoppableImageDot
  productData?: ProductData | null
}

/**
 * Shoppable Dots have an animated circle shape to indicate a product or topic and a corresponding popup
 */
function ContentstackShoppableDot ({ data, productData }: ContentstackShoppableDotProps): ReactElement | null {
  const [reference, setReference] = useState<HTMLDivElement | null>(null)

  if (data == null || (data.content?.type !== 'TEXT' && productData == null)) {
    return null
  }

  return (
    <>
      <ContentstackShoppableDotPopup
        data={data}
        productData={productData}
        reference={reference}
      />
      <div
        ref={setReference}
        className='opacity-75 bg-white border cursor-pointer shoppable-dot rounded-circle'
        style={{ left: data.x, top: data.y }}
      />
      <style jsx>{`
        @keyframes pulsing {
          0% {
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
          }
          70% {
            box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
          }
        }

        .shoppable-dot {
          --bs-border-color: #aaa;
          filter: drop-shadow(0 0 4px rgba(0,0,0,.4));
          position: absolute;
          height: 1.8rem;
          width: 1.8rem;
          box-shadow: 0 0 0 rgba(255, 255, 255, 0.8);
          animation: pulsing 2s infinite;
        }
      `}
      </style>
    </>
  )
}

export { ContentstackShoppableDot }
