import { Card, CardBody } from '@stuller/stullercom/ui'
import { ContentstackBlogCardBody } from './ContentstackBlogCardBody'
import { ContentstackBlogCardFeaturedImage } from './ContentstackBlogCardFeaturedImage'
import { ContentstackBlogCardHeader } from './ContentstackBlogCardHeader'
import { ContentstackBlogCardLink } from './ContentstackBlogCardLink'
import { isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { type BlogCard } from './types'
import { type ReactElement } from 'react'
import clsx from 'clsx'

interface ContentstackBlogCardProps {
  card?: BlogCard
  className?: string
  index?: number
}

/**
 * Individual Blog Card rendered inside <ContentstackBlogCardGroup /> component instances
 * @memberof ContentstackBlogCardGroup
 */
function ContentstackBlogCard ({ card, className, index }: ContentstackBlogCardProps): ReactElement | null {
  const {
    title,
    featured_image: featuredImage,
    header,
    body,
    link
  } = card ?? {}

  const isHeadingEmpty = isRteEmpty(header?.heading?.json)
  const isBodyRichTextEmpty = isRteEmpty(body?.rich_text?.json)
  const isFeaturedImageEmpty = isSimpleImageEmpty(featuredImage)
  const blogCardClassNames = clsx('h-100', className)

  if (isHeadingEmpty && isBodyRichTextEmpty && isFeaturedImageEmpty) {
    return null
  }

  return (
    <Card
      className={blogCardClassNames}
      data-gtm-click-section='card'
      data-gtm-click-subsection1={title}
      data-gtm-click-subsection1-index={index}
    >
      <CardBody className='p-4 pb-6'>
        <div className='row gy-4'>
          <ContentstackBlogCardFeaturedImage data={featuredImage} />
          <ContentstackBlogCardHeader data={header} />
          <ContentstackBlogCardBody data={body} />
          <ContentstackBlogCardLink
            data={link}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export {
  ContentstackBlogCard
}
