import { type ReactElement } from 'react'
import { type TopicInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackYoutubeVideo, isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'

interface ContentstackTopicFeaturedMediaProps {
  data: TopicInfoFragment['featured_media']
  className: string
}

/**
 * Renders the featured media inside of a ContentstackTopic component
 * If "none" is selected, the component will return null
 * @memberof ContentstackTopic
 */
function ContentstackTopicFeaturedMedia ({ data, className }: ContentstackTopicFeaturedMediaProps): ReactElement | null {
  const {
    featured_video: featuredVideo,
    featured_image: featuredImage
  } = data ?? {}

  if (!isYoutubeVideoEmpty(featuredVideo)) {
    return (
      <div className={className}><ContentstackYoutubeVideo data={featuredVideo} /></div>
    )
  }

  if (!isSimpleImageEmpty(featuredImage)) {
    return (
      <div className={className}><ContentstackSimpleImage data={featuredImage} /></div>
    )
  }

  return null
}

export {
  ContentstackTopicFeaturedMedia
}
