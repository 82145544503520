import { clsx } from 'clsx'
import { ContentstackCard } from './ContentstackCard'
import { MultiItemCarousel, MultiItemCarouselControl, MultiItemCarouselIndicators, MultiItemCarouselInner, MultiItemCarouselItem, useMultiItemCarousel } from '@stuller/stullercom/ui'
import { stringToHtmlId } from '@stuller/shared/util/core'
import { type CardGroupInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'

interface ContentstackCardGroupDisplayProps {
  appearance?: CardGroupInfoFragment['appearance']
  cards?: CardGroupInfoFragment['cards'] | null
  columnClass: string | false
}

const cardGroupWidths: Record<string, number> = {
  half: 650,
  third: 450,
  fourth: 350
}

function ContentstackCardGroupDisplay ({
  appearance,
  cards,
  columnClass
}: ContentstackCardGroupDisplayProps): ReactElement | null {
  const containerClassNames = clsx(columnClass)
  const multiItemCarouselProps = useMultiItemCarousel()

  if (appearance?.display_type === 'carousel') {
    const cardWidth = cardGroupWidths[appearance?.card_width ?? 'fourth']

    return (
      <MultiItemCarousel {...multiItemCarouselProps}>
        <MultiItemCarouselInner gap='2rem' className='pb-4 pb-md-0'>
          {cards?.map((card, index) => (
            <MultiItemCarouselItem id={stringToHtmlId(card?.title, 'card-')} key={index}>
              <ContentstackCard
                card={card}
                alignmentClass={`text-${appearance?.card_alignment}`}
                style={{ maxWidth: cardWidth, minWidth: cardWidth }}
                index={index}
              />
            </MultiItemCarouselItem>
          ))}
        </MultiItemCarouselInner>
        <MultiItemCarouselControl direction='prev' />
        <MultiItemCarouselControl direction='next' />
        <MultiItemCarouselIndicators />
      </MultiItemCarousel>
    )
  }

  return (
    <>
      {cards?.map((card, index) => (
        <div className={containerClassNames} key={index}>
          <ContentstackCard
            card={card}
            alignmentClass={`text-${appearance?.card_alignment}`}
          />
        </div>
      ))}
    </>
  )
}

export {
  ContentstackCardGroupDisplay
}
