import { useMemo } from 'react'
import { type ApolloError } from '@apollo/client'
import { type ItemsItemInput, useGetActiveProductsAndGroupsByIdsQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { type ProductData, type ProductMultiItemCarouselProps } from '@stuller/stullercom/ui'
import type { RelatedItem } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'
import {
  trackViewItemList,
  trackSelectItem,
  type ProductOrGroupTracking
} from '@stuller/stullercom/feat/google-tag-manager'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackRelatedItemsParams {
  /**
   * The Contentstack related items field
   */
  relatedItems: RelatedItem[]
  /**
   * The related items source (AKA recommendation source, AKA item_list_id) to apply to items for tracking
   */
  relatedItemsSource?: string
}

interface ContentstackRelatedItemsReturn extends Pick<ProductMultiItemCarouselProps, 'products' | 'onLoad'> {
  /**
   * Indicates the query is loading
   */
  loading: boolean
  /**
   * Error from the GraphQL call if any
   */
  error?: ApolloError
}

/**
 * Constructs the URL for the product card links
 * - Example URLS:
 *   - Product `/products/details/65378/?iid=20235912`
 *   - Group `/products/details/?gid=213693`
 * - Query params:
 *   - `recommendationSource` = 'featureditemlisting'
 *   - `recommendationType` = 'group' or 'product'
 *   - `recommendationId` = group id or inventory item id
 *   - `recommendationIndex` = index in the list (zero based)
 */
function getRelatedItemUrl (item: RelatedItem, index: number, relatedItemsSource?: string): string {
  const recommendationSource: string = relatedItemsSource ?? ''
  const recommendationIndex = index
  let recommendationType = ''
  let recommendationId = ''
  let url = '/products/details/'

  if (item.type === 'PRODUCT') {
    url += `${encodeURIComponent(item.itemNumber.split(':')[0])}/`
    url += `?iid=${encodeURIComponent(item.productId)}`
    recommendationType = 'product'
    recommendationId = item.productId
  } else if (item.type === 'PRODUCT_GROUP') {
    url += `?gid=${encodeURIComponent(item.groupId)}`
    recommendationType = 'group'
    recommendationId = item.groupId
  }

  url += `&recommendationSource=${encodeURIComponent(recommendationSource)}`
  url += `&recommendationType=${encodeURIComponent(recommendationType)}`
  url += `&recommendationId=${encodeURIComponent(recommendationId)}`
  url += `&recommendationIndex=${encodeURIComponent(recommendationIndex)}`

  return url
}

/**
 * Returns loading state and active items based on the provided param
 */
function useContentstackRelatedItems ({
  relatedItems = [],
  relatedItemsSource
}: ContentstackRelatedItemsParams): ContentstackRelatedItemsReturn {
  const auth = useAuth()
  // Inputs to query for product and product group data
  const [productsInput, productGroupsInput] = useMemo(() => {
    return relatedItems.reduce<[ItemsItemInput[], string[]]>(([productsInput, productGroupsInput], item) => {
      if (item.type === 'PRODUCT') {
        productsInput.push({ id: { id: item.productId, type: 'PRODUCT' } })
      } else if (item.type === 'PRODUCT_GROUP') {
        productGroupsInput.push(item.groupId)
      }

      return [productsInput, productGroupsInput]
    }, [[], []])
  }, [relatedItems])

  /**
   * Query to get related item data needed for product card lists/tracking/etc.
   */
  const { loading, data, error } = useGetActiveProductsAndGroupsByIdsQuery({
    variables: { input: { items: productsInput }, ids: productGroupsInput },
    skip: productsInput.length === 0 && productGroupsInput.length === 0,
    ssr: true
  })

  /**
   * Create an array of ProductData[] from active product items and active product groups since we only want to return active ones
   */
  const [products, onLoad]: [ProductData[], () => void] = useMemo(() => {
    let index = 0

    const [products, trackings] = relatedItems.reduce<[ProductData[], ProductOrGroupTracking[]]>(([products, trackings], item) => {
      if (item.type === 'PRODUCT') {
        const itemMatch = data?.activeItems.find(i => i?.id === item.productId)

        if (itemMatch?.__typename === 'Product') {
          const tracking: ProductOrGroupTracking = {
            ...itemMatch,
            index: index++,
            item_list_id: `product: ${relatedItemsSource}`
          }
          const product: ProductData = {
            id: item.productId,
            image: !isStringEmpty(item.imageUrl) ? item.imageUrl : itemMatch.image ?? '',
            title: !isStringEmpty(item.title) ? item.title : itemMatch.title,
            url: getRelatedItemUrl(item, index, relatedItemsSource),
            itemNumber: !isStringEmpty(item.title) ? item.itemNumber : itemMatch.itemNumber,
            onClick: () => { trackSelectItem(tracking, auth) }
          }

          trackings.push(tracking)
          products.push(product)
        }
      } else if (item.type === 'PRODUCT_GROUP') {
        const groupMatch = data?.activeProductGroupsByIds.find(i => i?.id === item.groupId)

        if (groupMatch?.__typename === 'ProductGroup') {
          const tracking: ProductOrGroupTracking = {
            ...groupMatch,
            index: index++,
            item_list_id: `group: ${relatedItemsSource}`
          }
          const product: ProductData = {
            id: item.groupId,
            image: !isStringEmpty(item.imageUrl) ? item.imageUrl : groupMatch.image ?? '',
            title: !isStringEmpty(item.title) ? item.title : groupMatch.title,
            url: getRelatedItemUrl(item, index, relatedItemsSource),
            groupId: !isStringEmpty(item.groupId) ? item.groupId : groupMatch.id,
            onClick: () => { trackSelectItem(tracking, auth) }
          }

          trackings.push(tracking)
          products.push(product)
        }
      }

      return [products, trackings]
    }, [[], []])

    return [
      products,
      () => { trackViewItemList(trackings, auth) }
    ]
  }, [relatedItems, relatedItemsSource, data, auth])

  return {
    loading,
    products,
    error,
    onLoad
  }
}

export {
  useContentstackRelatedItems
}
