import { ContentstackSplitTopicTopic } from './ContentstackSplitTopicTopic'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { type ReactElement } from 'react'
import { type SplitTopic } from './types'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackSplitTopicProps {
  data?: SplitTopic
}

function ContentstackSplitTopic ({ data }: ContentstackSplitTopicProps): ReactElement | null {
  const {
    appearance,
    split_topics: splitTopics,
    targetingConnection,
    title
  } = data ?? {}

  const spacingInfo = {
    ...appearance?.min_height,
    spacing_xs: appearance?.min_height?.spacing_xs ?? 630,
    spacing_lg: appearance?.min_height?.spacing_lg ?? 720
  }

  const spacing = useContentstackSpacing('height', 'min', spacingInfo)
  const columnClassNames = clsx('col-12 col-lg-6', spacing?.className)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isTopicEmpty = isArrayEmpty(splitTopics)
  if (!showContent || isTopicEmpty) {
    return null
  }

  return (
    <section className='mw-xxxl m-auto' id={contentstackTitleToHtmlId(title)}>
      <div className='row g-0'>
        {splitTopics?.map((topic, index) => !isRteEmpty(topic?.header?.heading) && (
          <div className={columnClassNames} key={index}>
            <ContentstackSplitTopicTopic data={topic} />
          </div>
        ))}
      </div>
      {spacing?.styles}
    </section>
  )
}

export {
  ContentstackSplitTopic
}
