import { type ReactElement } from 'react'
import { type ScrollNavInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { Button } from '@stuller/stullercom/ui'
import { isStringEmpty } from '@stuller/shared/util/core'
import clsx from 'clsx'

interface ContentstackScrollNavLinkProps {
  data?: NonNullable<ScrollNavInfoFragment['navigation_links']>[0]
  /**
   * Currently selected link ID
   */
  selectedId: string
  /**
   * handles page scrolling when clicking on a link
   */
  onClick: () => void
}

/**
 * Component for individual links in a ContentstackScrollNav
 */
function ContentstackScrollNavLink ({ data, selectedId, onClick }: ContentstackScrollNavLinkProps): ReactElement | null {
  const {
    link_id: linkId,
    name
  } = data ?? {}
  if (isStringEmpty(linkId) || isRteEmpty(name?.json)) {
    return null
  }
  const isLink = linkId.includes('/')
  const isExternalLink = linkId.includes('https://')
  const linkURL = isExternalLink || isLink ? linkId : `#${linkId}`

  const buttonClassNames = clsx(
    linkId === selectedId ? 'btn-primary active' : 'btn-outline-secondary btn-outline-fill'
  )

  return (
    <Button
      pill
      tag='a'
      className={buttonClassNames}
      onClick={onClick}
      href={linkURL}
      target={isExternalLink ? '_blank' : undefined}
    >
      <ContentstackJsonRte excludeOuterTags json={name?.json} />
    </Button>
  )
}

export {
  ContentstackScrollNavLink
}
