import { type ReactElement } from 'react'
import { type CardWithBackgroundBody } from './types'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'

interface ContentstackCardWithBackgroundBodyProps {
  data: CardWithBackgroundBody
}

/**
 * Renders the rich text and button in a ContentstackCardWithBackground
 * @memberof ContentstackCardWithBackgroundGroup
 */
function ContentstackCardWithBackgroundBody ({ data }: ContentstackCardWithBackgroundBodyProps): ReactElement | null {
  const {
    rich_text: richText,
    button
  } = data ?? {}

  const emptyRichText = isRteEmpty(richText?.json)
  const emptyButton = isButtonEmpty(button)

  if (emptyRichText && emptyButton) {
    return null
  }

  return (
    <>
      {!emptyRichText && (
        <div className='col-12 mb-n3'>
          <ContentstackJsonRte json={richText?.json} />
        </div>)}
      {!emptyButton && (
        <div className='col-12'>
          <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
        </div>)}

    </>
  )
}

export {
  ContentstackCardWithBackgroundBody
}
