import { clsx } from 'clsx'
import { ContentstackButton } from '../contentstack-button/ContentstackButton'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { ContentstackVerticalBackgroundCard } from './ContentstackVerticalBackgroundCard'
import { isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { MultiItemCarousel, MultiItemCarouselControl, MultiItemCarouselIndicators, MultiItemCarouselInner, MultiItemCarouselItem, useMultiItemCarousel } from '@stuller/stullercom/ui'
import { isArrayEmpty, stringToHtmlId } from '@stuller/shared/util/core'
import { type ReactElement } from 'react'
import { type VerticalBackgroundCardGroupInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'

export interface ContentstackVerticalBackgroundCardGroupProps {
  data?: VerticalBackgroundCardGroupInfoFragment | null
}

function ContentstackVerticalBackgroundCardGroup ({ data }: ContentstackVerticalBackgroundCardGroupProps): ReactElement | null {
  const {
    appearance,
    button,
    section_header: sectionHeader,
    targetingConnection,
    title,
    vertical_background_cards: verticalBackgroundCards
  } = data ?? {}

  const multiItemCarouselProps = useMultiItemCarousel()
  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', 'y', appearance?.spacing_y)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isGroupEmpty = isArrayEmpty(verticalBackgroundCards)
  if (!showContent || isGroupEmpty) {
    return null
  }

  const buttonIsEmpty = isRteEmpty(button?.text?.json)
  const buttonAlignment = appearance?.button_alignment
  const layout = appearance?.section_header_position

  const sectionClassNames = clsx(
    'mw-xxxl m-auto',
    background.className
  )

  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8 d-print-none',
    spacing?.className
  )

  const headerColumnClassNames = clsx(
    'col-12',
    layout === 'left' && 'col-lg-3'
  )

  const contentColumnClassNames = clsx(
    'col-12',
    layout === 'left' && 'col-lg-9'
  )

  const bottomButtonColumnClassNames = clsx(
    'col-12',
    layout === 'left' && 'd-lg-none'
  )

  const buttonClassNames = clsx(
    'flex-row flex-nowrap',
    buttonAlignment === 'center' && 'justify-content-center',
    buttonAlignment === 'left' && 'justify-content-start',
    buttonAlignment === 'right' && 'justify-content-end'
  )

  return (
    <section className={sectionClassNames} id={contentstackTitleToHtmlId(title)} data-gtm-click-subsection1={title} data-gtm-click-section='topic'>
      <div className={containerClassNames}>
        <div className='row gy-5'>

          <div className={headerColumnClassNames}>
            <div className='d-flex flex-column flex-nowrap align-items-center justify-content-center h-100'>
              {sectionHeader != null && (
                <div className='row'>
                  <ContentstackSectionHeader data={sectionHeader} />
                </div>
              )}
              {!buttonIsEmpty && layout === 'left' && (
                <div className={`${buttonClassNames} mt-4 d-none d-lg-flex`}>
                  <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
                </div>
              )}
            </div>
          </div>

          <div className={contentColumnClassNames}>
            <MultiItemCarousel {...multiItemCarouselProps} className='m-n3'>
              <MultiItemCarouselInner gap='2rem' className='pb-4 pb-md-0'>
                {verticalBackgroundCards?.map((card, index) => card != null && (
                  <MultiItemCarouselItem id={stringToHtmlId(card.title, 'item-')} key={card.title}>
                    <ContentstackVerticalBackgroundCard data={card} index={index} />
                  </MultiItemCarouselItem>
                ))}
              </MultiItemCarouselInner>
              <MultiItemCarouselControl direction='prev' />
              <MultiItemCarouselControl direction='next' />
              <MultiItemCarouselIndicators />
            </MultiItemCarousel>
          </div>

          {!buttonIsEmpty && (
            <div className={bottomButtonColumnClassNames}>
              <div className={`${buttonClassNames} d-flex`}>
                <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
              </div>
            </div>
          )}

        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackVerticalBackgroundCardGroup
}
