import type { LargeShoppingBucketHeader } from './types'
import { type ReactElement } from 'react'
import { productBadges, type ProductBadgeType } from '@stuller/stullercom/ui'
import clsx from 'clsx'

interface ContentstackLargeShoppingBucketCardBadgeProps {
  data: NonNullable<LargeShoppingBucketHeader>['badge']
}

/**
 * Component for displaying the badge as the bucket's image
 */
function ContentstackLargeShoppingBucketCardBadge ({
  data
}: ContentstackLargeShoppingBucketCardBadgeProps): ReactElement | null {
  const type = data as ProductBadgeType
  const { children, color, text } = productBadges[type ?? 'new']
  const cardBadgeClassNames = clsx(
    'd-flex align-items-center justify-content-center rounded-3 h-100 w-100',
    text != null ? `text-${text}` : 'text-white',
    `bg-${color}`
  )

  return (
    <div className='h-100 p-3 pb-0 p-lg-4 pb-lg-0'>
      <div className={cardBadgeClassNames}>
        <p className='m-0 h2 fw-bold'>{children}</p>
      </div>
    </div>
  )
}

export {
  ContentstackLargeShoppingBucketCardBadge
}
