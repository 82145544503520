import { clsx } from 'clsx'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { Carousel, CarouselControl, CarouselIndicators, CarouselInner, CarouselItem } from '@stuller/stullercom/ui'
import { useCallback, useEffect, useRef, type ReactElement } from 'react'
import { type TestimonialsInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import { ContentstackTestimonial } from './ContentstackTestimonial'
import { useCounter, useResizeObserver } from '@stuller/shared/util/react-hooks'

export interface ContentstackTestimonialsProps {
  data?: TestimonialsInfoFragment | null
}

export type ContentstackTestimonialType = NonNullable<TestimonialsInfoFragment['testimonials']>[0]

function ContentstackTestimonials ({ data }: ContentstackTestimonialsProps): ReactElement | null {
  const {
    appearance,
    section_header: sectionHeader,
    targetingConnection,
    title,
    testimonials
  } = data ?? {}

  // carousel variable definitions
  const carouselArray = testimonials ?? [] as ContentstackTestimonialType[]
  const [index, setIndex, handlePrev, handleNext] = useCounter(0, {
    max: carouselArray.length - 1
  })

  const sectionClassNames = clsx(
    'contentstack-testimonials mw-xxxl m-auto',
    `bg-${appearance?.background_color}`
  )

  const carouselRef = useRef<HTMLDivElement>(null)

  const setCarouselHeight = useCallback(() => {
    const carousel = carouselRef.current

    if (carousel == null) {
      return null
    }

    const items = carousel.querySelectorAll<HTMLElement>('.carousel-item') ?? []
    let max = 0
    carousel.style.height = 'auto'

    for (const item of items) {
      item.setAttribute('style', 'display: block !important')
      const slideHeight = item.offsetHeight
      max = Math.max(max, slideHeight)
      item.style.display = ''
    }

    carousel.style.height = `calc(${max}px + 1.5rem)`
  }, [])

  useResizeObserver(setCarouselHeight, carouselRef)

  useEffect(() => {
    setCarouselHeight()
  }, [setCarouselHeight])

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || carouselArray.length === 0) {
    return null
  }

  return (
    <section
      className={sectionClassNames}
      id={contentstackTitleToHtmlId(title)}
      data-gtm-click-section='topic'
      data-gtm-click-subsection1={title}
    >
      <div className='container-lg mw-lg px-4 py-6 py-md-8 d-print-none'>
        <div className='row pb-6'>

          <div className='col-12 pb-2'>
            <div className='d-flex flex-column flex-nowrap align-items-center justify-content-center h-100'>
              {sectionHeader != null && (
                <div className='row'>
                  <ContentstackSectionHeader data={sectionHeader} />
                </div>
              )}
            </div>
          </div>

          <div className='col-12 d-flex justify-content-center'>
            <Carousel
              className='w-100'
              index={index}
              length={carouselArray.length}
              onChange={setIndex}
              popout
              ref={carouselRef}
              style={{ maxWidth: '800px' }}
            >
              <CarouselInner className='p-4 h-100'>
                {carouselArray?.map((item, i) => item != null && (
                  <CarouselItem key={i} itemIndex={i} className='h-100'>
                    <ContentstackTestimonial
                      data={item}
                      index={i}
                    />
                  </CarouselItem>
                ))}
              </CarouselInner>
              <CarouselControl className='pe-0' direction='prev' onClick={handlePrev} />
              <CarouselControl className='ps-0' direction='next' onClick={handleNext} />
              <CarouselIndicators className='mt-0' />
            </Carousel>
          </div>
        </div>
      </div>
      <style jsx>{`
        .contentstack-testimonials :global(p) {
          margin-bottom: 0;
        }
      `}
      </style>
    </section>
  )
}

export {
  ContentstackTestimonials
}
