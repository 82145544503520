import { type ReactElement } from 'react'
import { type PublicationCarouselInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { isArrayEmpty } from '@stuller/shared/util/core'

interface ContentstackPublicationCarouselItemProps {
  data?: NonNullable<PublicationCarouselInfoFragment['publications']>[0]
  index?: number
}

/**
 * Individual carousel item inside of a ContentstackPublicationCarousel
 * @memberof ContentstackPublicationCarousel
 */
function ContentstackPublicationCarouselItem ({ data, index }: ContentstackPublicationCarouselItemProps): ReactElement | null {
  const {
    title,
    buttons,
    heading,
    image,
    rich_text: richText
  } = data ?? {}

  const emptyHeadingJson = isRteEmpty(heading?.json)
  const emptyRichTextJson = isRteEmpty(richText?.json)

  return (
    <div
      className='d-flex flex-column h-100 text-center'
      style={{ width: '240px' }}
      data-gtm-click-subsection1={title}
      data-gtm-click-subsection1-index={index}
    >
      {!isSimpleImageEmpty(image) && (
        <div className='d-flex justify-content-center mb-4'>
          <ContentstackSimpleImage data={image} style={{ height: '280px' }} />
        </div>
      )}
      {!emptyHeadingJson && (
        <h3 className='fw-normal'>
          <ContentstackJsonRte excludeOuterTags json={heading?.json} />
        </h3>
      )}
      {!emptyRichTextJson && (
        <ContentstackJsonRte json={richText?.json} />
      )}
      {!isArrayEmpty(buttons) && (
        <div className='d-flex h-100 justify-content-center align-items-end'>
          <div className='row gy-3 justify-content-center'>
            {buttons?.map((button, i) => !isButtonEmpty(button) && (
              <div
                className='col-auto'
                key={i}
              >
                <ContentstackButton data={button} data-gtm-click-subsection2={`${i > 0 ? 'secondary' : 'primary'}-cta`} />
              </div>
            ))}
          </div>
        </div>)}
    </div>
  )
}

export {
  ContentstackPublicationCarouselItem
}
