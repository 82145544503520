import { type ReactElement } from 'react'
import { ContentstackShoppableImage } from './ContentstackShoppableImage'
import { isStringEmpty } from '@stuller/shared/util/core'
import clsx from 'clsx'

interface ContentstackShoppableResponsiveImageContainerProps {
  imageSource: any
  altText: any
  className?: string
  largerImage?: string
  title: string

}

/**
 * The Shoppable Responsive Image is an image that allows multiple Shoppable Image sources that display depending on the user’s screen width.
 */
function ContentstackShoppableResponsiveImageContainer ({ altText, imageSource, className, largerImage, title }: ContentstackShoppableResponsiveImageContainerProps): ReactElement | null {
  const shoppableImageClassNames = clsx(
    className,
    !isStringEmpty(largerImage) && `d-${largerImage}-none`
  )

  return (
    <ContentstackShoppableImage
      altText={altText}
      data={imageSource}
      title={title}
      className={shoppableImageClassNames}
    />
  )
}

export {
  ContentstackShoppableResponsiveImageContainer
}
