import { type ReactElement } from 'react'
import { type CardWithBackgroundHeader } from './types'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'

interface ContentstackCardWithBackgroundHeaderProps {
  data: CardWithBackgroundHeader
}

/**
 * Renders the heading of a ContentstackCardWithBackground
 * @memberof ContentstackCardWithBackgroundGroup
 */
function ContentstackCardWithBackgroundHeader ({ data }: ContentstackCardWithBackgroundHeaderProps): ReactElement | null {
  const {
    heading
  } = data ?? {}

  if (isRteEmpty(heading?.json)) {
    return null
  }

  return (
    <div className='col-12 mb-n3'>
      <h2>
        <ContentstackJsonRte excludeOuterTags json={heading?.json} />
      </h2>
    </div>
  )
}

export {
  ContentstackCardWithBackgroundHeader
}
