import { clsx } from 'clsx'
import { ContentstackIconListItem } from './ContentstackIconListItem'
import { type IconList } from './types'
import { type ReactElement } from 'react'
import { isArrayEmpty } from '@stuller/shared/util/core'

interface ContentstackIconListProps {
  data?: IconList
}

/**
 * Helper function to not render any parent elements when the icon list is empty
 */
function isIconListEmpty (data?: IconList): boolean {
  return isArrayEmpty(data?.items)
}

/**
 * The Icon List consists of a list of items, each preceded by an icon.
 *
 * The icons are visual cues that relate to the words or ideas in their
 * respective list items to improve a user’s understanding.
 *
 * Icon Lists can be used to group related messaging points.
 */
function ContentstackIconList ({ data }: ContentstackIconListProps): ReactElement | null {
  const {
    appearance,
    default_icon: defaultIcon,
    items
  } = data ?? {}

  if (isIconListEmpty(data)) {
    return null
  }

  return (
    <section className={clsx(appearance?.text_color != null && `text-${appearance?.text_color}`)}>
      <ul className='list-unstyled mb-n5'>
        {items?.map((item, key) => (
          <ContentstackIconListItem
            defaultIcon={defaultIcon}
            data={item}
            key={key}
          />))}
      </ul>
    </section>
  )
}

export {
  ContentstackIconList,
  isIconListEmpty
}
