import { clsx } from 'clsx'
import { ContentstackButton } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { ProductMultiItemCarousel } from '@stuller/stullercom/ui'
import { type ProductCardCarouselInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackRelatedItems } from '../contentstack-related-items/useContentstackRelatedItems'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'

interface ContentstackProductCardCarouselInnerProps {
  data?: ProductCardCarouselInfoFragment | null
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

function ContentstackProductCardCarouselInner ({
  data
}: ContentstackProductCardCarouselInnerProps): ReactElement | null {
  const {
    body,
    appearance,
    section_header: sectionHeader,
    title
  } = data ?? {}

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', 'y', appearance?.spacing_y)
  const layout = appearance?.section_header_position
  const buttonAndRichTextAlignment = appearance?.button_rich_text_below_carousel_alignment
  const buttonIsEmpty = isRteEmpty(body?.button?.text?.json)
  const richTextIsEmpty = isRteEmpty(body?.rich_text_below_carousel?.json)

  const { products, onLoad } = useContentstackRelatedItems({
    relatedItems: body?.product_cards?.relatedItems,
    relatedItemsSource: `featureditems-carousel-${title}`
  })
  if (products.length === 0) {
    return null
  }

  const sectionClassNames = clsx(
    'd-print-none mw-xxxl m-auto',
    background.className
  )

  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8 d-print-none',
    spacing?.className
  )

  const headerColumnClassNames = clsx(
    'col-12',
    layout === 'left' && 'col-lg-3'
  )

  const contentColumnClassNames = clsx(
    'col-12',
    layout === 'left' && 'col-lg-9'
  )

  const bottomRichTextClassNames = clsx(
    'col-12 d-flex',
    sectionHeader?.appearance?.text_color === 'white' && 'text-white links-white',
    (buttonAndRichTextAlignment != null && alignmentMap[buttonAndRichTextAlignment]) ?? 'justify-content-start',
    layout === 'left' && 'd-lg-none'
  )

  const bottomButtonColumnClassNames = clsx(
    'col-12',
    layout === 'left' && 'd-lg-none'
  )

  const buttonClassNames = clsx(
    'flex-row flex-nowrap',
    buttonAndRichTextAlignment === 'center' && 'justify-content-center',
    buttonAndRichTextAlignment === 'left' && 'justify-content-start',
    buttonAndRichTextAlignment === 'right' && 'justify-content-end'
  )

  const leftAlignedRichTextClassNames = clsx(
    buttonClassNames,
    sectionHeader?.appearance?.text_color === 'white' && 'text-white links-white'
  )

  return (
    <section className={sectionClassNames} id={contentstackTitleToHtmlId(title)} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
      <div className={containerClassNames}>
        <div className='row gy-5'>

          <div className={headerColumnClassNames}>
            <div className='d-flex flex-column flex-nowrap align-items-center justify-content-center h-100'>
              {sectionHeader != null && (
                <div className='row'>
                  <ContentstackSectionHeader data={sectionHeader} />
                </div>
              )}
              {!richTextIsEmpty && layout === 'left' && (
                <div className={`${leftAlignedRichTextClassNames} mt-4 d-none d-lg-flex`}>
                  <p className='mb-0'>
                    <ContentstackJsonRte excludeOuterTags json={body?.rich_text_below_carousel?.json} />
                  </p>
                </div>
              )}
              {!buttonIsEmpty && layout === 'left' && (
                <div className={`${buttonClassNames} mt-4 d-none d-lg-flex`} data-gtm-click-subsection1={title} data-gtm-click-section='topic'>
                  <ContentstackButton data={body?.button} data-gtm-click-subsection2='primary-cta' />
                </div>
              )}
            </div>
          </div>

          <div className={contentColumnClassNames}>
            <ProductMultiItemCarousel products={products} onLoad={onLoad} />
          </div>

          {!richTextIsEmpty && (
            <div className={bottomRichTextClassNames}>
              <p className='mb-0'>
                <ContentstackJsonRte excludeOuterTags json={body?.rich_text_below_carousel?.json} />
              </p>
            </div>
          )}

          {!buttonIsEmpty && (
            <div className={bottomButtonColumnClassNames}>
              <div className={`${buttonClassNames} d-flex`} data-gtm-click-subsection1={title} data-gtm-click-section='topic'>
                <ContentstackButton data={body?.button} data-gtm-click-subsection2='primary-cta' />
              </div>
            </div>
          )}

        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackProductCardCarouselInner
}
