import { type ReactElement } from 'react'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { type PageHeaderHeader } from './types'
import clsx from 'clsx'

interface ContentstackPageHeaderHeaderProps {
  data: PageHeaderHeader
  headingFontSize?: string | null
}

/**
 * Contains the important information that helps users understand the structure and find what they are looking for.
 */
function ContentstackPageHeaderHeader ({ data, headingFontSize }: ContentstackPageHeaderHeaderProps): ReactElement | null {
  const {
    image,
    heading, subheading
  } = data ?? {}

  const hasEmptyImage = isSimpleImageEmpty(image)
  const hasEmptyHeading = isRteEmpty(heading?.json)
  const hasEmptySubheading = isRteEmpty(subheading?.json)

  if (hasEmptyImage && hasEmptyHeading && hasEmptySubheading) {
    return null
  }

  const headingClassNames = clsx(
    'mb-0',
    headingFontSize ?? ''
  )

  return (
    <>
      {!hasEmptyImage && (
        <div className='mb-4'>
          <ContentstackSimpleImage data={image} />
        </div>
      )}
      {!hasEmptyHeading && (
        <h1 className={headingClassNames}>
          <ContentstackJsonRte json={heading?.json} excludeOuterTags />
        </h1>
      )}

      {!hasEmptySubheading && (
        <h3 className='mt-2 mb-0'>
          <ContentstackJsonRte json={subheading?.json} excludeOuterTags />
        </h3>
      )}
    </>
  )
}

export {
  ContentstackPageHeaderHeader
}
