import { byPrefixAndName } from '@awesome.me/kit-3dbd93c064/icons'
import { type ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type Maybe } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type SizeProp } from '@fortawesome/fontawesome-svg-core'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackIconProps {
  icon: Maybe<string> | undefined
  size?: SizeProp
  className?: string
  color?: string
  defaultIcon?: string
}

/**
 * The Icon is a graphic representation that visually communicates meaning to users.
 *
 * It reinforces and eases understanding of actions, ideas, or concepts described in copy.
 */
function ContentstackIcon ({
  icon,
  size,
  className,
  color = '',
  defaultIcon
}: ContentstackIconProps): ReactElement | null {
  const currentIcon = icon ?? defaultIcon

  if (isStringEmpty(currentIcon)) {
    return null
  }

  const [iconPrefix, iconName] = currentIcon?.split(/-(.*)/s)

  return (
    <FontAwesomeIcon
      icon={byPrefixAndName[iconPrefix][iconName]}
      className={className}
      color={color}
      size={size}
    />
  )
}

export {
  ContentstackIcon
}
