import { type ReactElement } from 'react'
import { ModalFooter } from '@stuller/stullercom/ui'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { type ContentstackModalProps } from './ContentstackModal'
import clsx from 'clsx'

/**
 * Two optional buttons at the bottom of the modal that provide users an option to take action.
 */
function ContentstackModalFooter ({ data }: ContentstackModalProps): ReactElement | null {
  const { appearance, buttons } = data ?? {}
  const footerClassNames = clsx(appearance?.buttons_orientation === 'left' && 'justify-content-start')

  return (
    <ModalFooter className={footerClassNames}>
      {buttons?.map((button, i) => !isButtonEmpty(button) && (
        <ContentstackButton key={i} data={button} />
      ))}
    </ModalFooter>
  )
}

export {
  ContentstackModalFooter
}
