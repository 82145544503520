import { ContentstackIconListItemIcon } from './ContentstackIconListItemIcon'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { type ReactElement } from 'react'
import { isStringEmpty } from '@stuller/shared/util/core'
import type { IconListDefaultIcon, IconListItem } from './types'

interface ContentstackIconListItemProps {
  defaultIcon?: IconListDefaultIcon
  data: IconListItem
}

/**
 * Individual item rendered inside <ContentstackIconList /> component instances
 * @memberof ContentstackIconList
 */
function ContentstackIconListItem ({ defaultIcon, data }: ContentstackIconListItemProps): ReactElement | null {
  const {
    heading,
    rich_text: richText,
    icon
  } = data ?? {}

  const iconToUse = isStringEmpty(icon?.name) ? defaultIcon : icon

  const hasEmptyHeading = isRteEmpty(heading?.json)
  const hasEmptyRichText = isRteEmpty(richText?.json)
  const hasEmptyIcon = isStringEmpty(iconToUse?.name)

  if (hasEmptyHeading && hasEmptyRichText && hasEmptyIcon) {
    return null
  }

  return (
    <li className='mb-5 p-0'>
      <div className='row gx-3'>
        {!hasEmptyIcon && (
          <div className='col-auto'>
            <ContentstackIconListItemIcon icon={iconToUse} />
          </div>
        )}
        {(!hasEmptyHeading || !hasEmptyRichText) && (
          <div className='col mb-n3'>
            {!hasEmptyHeading && (
              <h3 className='mb-1'>
                <ContentstackJsonRte json={heading?.json} excludeOuterTags />
              </h3>
            )}
            <ContentstackJsonRte json={richText?.json} />
          </div>
        )}
      </div>
    </li>
  )
}

export {
  ContentstackIconListItem
}
