import dynamic from 'next/dynamic'

export const ContentstackFilterShoppingBucketsEscapeHatch = dynamic(async () =>
  await import('./lib/contentstack-filter-shopping-buckets-escape-hatch/ContentstackFilterShoppingBucketsEscapeHatch')
    .then(m => m.ContentstackFilterShoppingBucketsEscapeHatch)
)

export const ContentstackTestEscapeHatch = dynamic(async () =>
  await import('./lib/contentstack-test-escape-hatch/ContentstackTestEscapeHatch')
    .then(m => m.ContentstackTestEscapeHatch)
)
