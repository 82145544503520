import { type ReactElement } from 'react'
import { type SearchBySerialNumberInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { stringToHtmlId } from '@stuller/shared/util/core'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'
import { SearchBySerialNumber } from '@stuller/stullercom/feat/serialized-pdp-pages'

interface ContentstackSearchBySerialNumberProps {
  data?: SearchBySerialNumberInfoFragment | null
}

/**
 * Renders a text input field that opens the results for a serial number in a new tab
 */
function ContentstackSearchBySerialNumber ({ data }: ContentstackSearchBySerialNumberProps): ReactElement | null {
  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(data?.targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || data == null) {
    return null
  }

  const sectionClassNames = clsx(
    'px-4 py-8 mw-xxxl m-auto',
    data?.appearance?.background != null && `bg-${data?.appearance?.background}`
  )

  return (
    <section className={sectionClassNames} id={stringToHtmlId(data?.title, 'section-')}>
      <div className='container-xxxl mw-xxl px-4'>
        <SearchBySerialNumber centered openInNewTab />
      </div>
    </section>
  )
}

export {
  ContentstackSearchBySerialNumber
}
