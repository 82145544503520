import { useCallback, useMemo, useState, type ReactElement } from 'react'
import { type CalloutNavInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte } from '@stuller/stullercom/feat/contentstack-json-rte'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import clsx from 'clsx'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { useInterval } from '@stuller/shared/util/react-hooks'
import { isStringEmpty } from '@stuller/shared/util/core'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'

interface ContentstackCalloutNavProps {
  data?: CalloutNavInfoFragment | null
}

/**
 * Component that renders a sticky navigation bar with links that scroll to specific sections of a content page
 */
function ContentstackCalloutNav ({ data }: ContentstackCalloutNavProps): ReactElement | null {
  const {
    title,
    header,
    targetingConnection,
    body,
    button,
    appearance
  } = data ?? {}

  const background = useContentstackBackground(appearance?.background)
  const endDate = useMemo(() => new Date(body?.countdown_date_time ?? ''), [body?.countdown_date_time])
  const [days, setDays] = useState<number>(0)
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [seconds, setSeconds] = useState<number>(0)
  const [distance, setDistance] = useState<number | null>(null)
  const showCountdown = distance != null && distance > 0

  const updateTime = useCallback(() => {
    const startDate = new Date()
    const distance = endDate.getTime() - startDate.getTime()
    // prevent countdown from going into negatives
    setDistance(distance)
    // Time calculations for days, hours, minutes and seconds
    setDays(Math.floor(distance / (1000 * 60 * 60 * 24)))
    setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
    setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
    setSeconds(Math.floor((distance % (1000 * 60)) / 1000))
  }, [endDate])

  useInterval(updateTime, distance == null || distance > 0 ? 1000 : null)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const navClassNames = clsx(
    'position-relative top-0 z-sticky mw-xxxl m-auto',
    background.className,
    appearance?.static === true ? '' : 'position-lg-sticky',
    !isStringEmpty(appearance?.text_color) && `text-${appearance?.text_color}`
  )

  const containerClassNames = clsx(
    'container-xxxl mw-xxl d-flex flex-wrap gap-2 p-4'
  )

  return (
    <nav
      className={navClassNames}
      data-gtm-click-section='topic'
      data-gtm-click-subsection1={title}
    >
      <div className={containerClassNames}>
        <h2 className='display-6 mb-0 flex-grow-1'><ContentstackJsonRte json={header?.heading?.json} excludeOuterTags /></h2>
        <div className='d-flex flex-wrap align-items-center gap-4'>
          {showCountdown && (
            <div className='d-flex gap-3 align-items-center flex-wrap'>
              <div>
                <span className='h2 me-3'>{days} Days</span>
                <span className='h2 '>{hours} Hours</span>
              </div>
              <div>
                <span className='h2 me-3'>{minutes} Minutes</span>
                <span className='h2'>{seconds} seconds</span>
              </div>
            </div>
          )}
          {!isButtonEmpty(button) &&
            <div>
              <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
            </div>}
        </div>
        {background.styles}
      </div>
    </nav>
  )
}

export {
  ContentstackCalloutNav
}
