import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import { isStringEmpty } from '@stuller/shared/util/core'
import { type IconListItemsIcon } from './types'
import { type ReactElement } from 'react'
import clsx from 'clsx'

interface ContentstackIconListItemProps {
  icon: IconListItemsIcon
}

/**
 * Icon rendered inside individual <ContentstackIconListItem /> component(s)
 * @memberof ContentstackIconListItem
 */
function ContentstackIconListItemIcon ({ icon }: ContentstackIconListItemProps): ReactElement {
  const cmsIconColor = clsx(!isStringEmpty(icon?.color) && `text-${icon?.color}`)
  const cmsIconName = icon?.name
  const cmsIconCircleSize: number = 50

  /**
   * Bootstrap classes applied to the circle wrapper
   */
  const wrapperClasses = clsx(
    'd-flex flex-column align-items-center justify-content-center',
    'bg-white shadow-sm rounded-circle',
    cmsIconColor
  )

  return (
    <div
      className={wrapperClasses}
      style={{
        maxHeight: cmsIconCircleSize,
        maxWidth: cmsIconCircleSize,
        minHeight: cmsIconCircleSize,
        minWidth: cmsIconCircleSize
      }}
    >
      <ContentstackIcon
        icon={cmsIconName}
        color={cmsIconColor}
        size='2x'
        defaultIcon='fas-check'
      />
    </div>
  )
}

export {
  ContentstackIconListItemIcon
}
