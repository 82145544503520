import { type ReactElement } from 'react'
import { type ShoppableResponsiveImageInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { isStringEmpty } from '@stuller/shared/util/core'
import { ContentstackShoppableResponsiveImageContainer } from './ContentstackShoppableResponsiveImageContainer'

interface ContentstackShoppableResponsiveImageProps {
  data?: ShoppableResponsiveImageInfoFragment | null
  title: string
}

type ImageSourceKeys = 'image_xs' | 'image_sm' | 'image_md' | 'image_lg' | 'image_xl' | 'image_xxl' | 'image_xxxl'
const imageSourcesData = [
  { size: 'xs', className: 'd-block' },
  { size: 'sm', className: 'd-none d-sm-block' },
  { size: 'md', className: 'd-none d-md-block' },
  { size: 'lg', className: 'd-none d-lg-block' },
  { size: 'xl', className: 'd-none d-xl-block' },
  { size: 'xxl', className: 'd-none d-xxl-block' },
  { size: 'xxxl', className: 'd-none d-xxxl-block' }
]

/**
 * The Shoppable Responsive Image is an image that allows multiple Shoppable Image sources that display depending on the user’s screen width.
 */
function ContentstackShoppableResponsiveImage ({ data, title }: ContentstackShoppableResponsiveImageProps): ReactElement | null {
  const {
    alt_text: altText = '',
    image_sources: imageSources
  } = data ?? {}

  if (imageSources == null) {
    return null
  }

  function getLargerImage (index: number): string | undefined {
    return imageSourcesData.find((larger, largerIndex) => largerIndex > index && hasImage(`image_${larger.size}` as ImageSourceKeys))?.size
  }

  function hasImage (size: ImageSourceKeys): boolean {
    return !isStringEmpty((imageSources)?.[size]?.url as string | null)
  }

  return (
    <div className='d-flex justify-content-center justify-content-md-end'>
      {imageSourcesData.map((imageSourceData, index) => (
        <ContentstackShoppableResponsiveImageContainer
          key={index}
          imageSource={imageSources[`image_${imageSourceData.size}` as ImageSourceKeys]}
          altText={altText}
          title={title}
          className={imageSourceData.className}
          largerImage={getLargerImage(index)}
        />
      ))}
    </div>
  )
}

export {
  ContentstackShoppableResponsiveImage
}
