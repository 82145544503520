import { stringToHtmlId } from '@stuller/shared/util/core'

const regex = /^(.*? +- +.*?)( +- +).*$/

/**
 * Ensures IDs the same for components that have targeting. E.g.,
 * both of these should have the ID `'section-findingspageheader'`
 * - `'Findings - Page Header - Showcase'`
 * - `'Findings - Page Header'`
 *
 * Calls `stringToHtmlId(text, 'section-)`
 *
 * @link https://stullerinc.atlassian.net/wiki/spaces/WEB/pages/3131703397/Contentstack+Modular+Block+Group+Titles
 */
function contentstackTitleToHtmlId (text: string | null | undefined): string {
  const newString = text?.replace(regex, '$1')

  return stringToHtmlId(newString, 'section-')
}

export {
  contentstackTitleToHtmlId
}
