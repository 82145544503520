import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackCardGroupDisplay } from './ContentstackCardGroupDisplay'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type CardGroup } from './types'
import { type ReactElement } from 'react'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackCardGroupProps {
  data?: CardGroup
}

const cardGroupColumns: Record<string, string> = {
  half: 'col-12 col-sm-6',
  third: 'col-12 col-sm-6 col-lg-4',
  fourth: 'col-12 col-sm-6 col-lg-3'
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

/**
 * The Card Group is a collection of cards.
 *
 * Each Card groups content relating to a single topic together in a way that makes it distinct from the surrounding content.
 */
function ContentstackCardGroup ({ data }: ContentstackCardGroupProps): ReactElement | null {
  const {
    title,
    section_header: sectionHeader,
    cards,
    button,
    appearance,
    targetingConnection
  } = data ?? {}

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', 'y', appearance?.spacing_y)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isGroupEmpty = isArrayEmpty(cards)
  if (!showContent || isGroupEmpty) {
    return null
  }

  const columns = (appearance?.card_width != null && cardGroupColumns[appearance.card_width]) ?? ''
  const emptyButton = isButtonEmpty(button)

  const sectionClassNames = clsx(
    'mw-xxxl m-auto',
    background.className
  )

  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className
  )

  const rowClassNames = clsx(
    'row gy-5',
    appearance?.card_alignment === 'center' ? 'justify-content-center' : 'justify-content-start'
  )

  const buttonClassNames = clsx(
    'col-12 mt-6 d-flex',
    (appearance?.button_alignment != null && alignmentMap[appearance.button_alignment]) ?? 'justify-content-start'
  )

  return (
    <section className={sectionClassNames} id={contentstackTitleToHtmlId(title)} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
      <div className={containerClassNames}>
        <div className={rowClassNames}>
          <ContentstackSectionHeader data={sectionHeader} />
          <ContentstackCardGroupDisplay
            appearance={appearance}
            cards={cards}
            columnClass={columns}
          />
          {!emptyButton && (
            <div className={buttonClassNames}>
              <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
            </div>)}
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackCardGroup
}
