import { Button, Modal, ModalBody, ModalHeader } from '@stuller/stullercom/ui'
import { ContentstackYoutubeVideoBase } from './ContentstackYoutubeVideoBase'
import { type ContentstackYoutubeVideoProps } from './ContentstackYoutubeVideo'
import { type ReactElement } from 'react'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { YOUTUBE_PLAY_ICON } from '@stuller/shared/util/constants'

/**
 * Clickable image opens modal with the video
 */
function ContentstackYoutubeVideoModal ({
  data,
  maxWidth = '100%',
  autoFocus = true
}: ContentstackYoutubeVideoProps): ReactElement | null {
  const { accessibility_title: title, video } = data ?? {}
  const [modalOpen, toggleModalOpen] = useToggle()

  return (
    <>
      <div className='bg-black col-12 rounded-3'>
        <Button
          aria-label={title ?? 'Open video modal'}
          className='btn bg-transparent p-0 border-0 position-relative'
          onClick={toggleModalOpen}
        >
          <img
            className='img-fluid w-100 rounded-3 pe-none'
            src={`https://img.youtube.com/vi/${video?.id}/maxresdefault.jpg`}
          />
          <img
            className='img-fluid h-auto position-absolute start-50 top-50 pe-none'
            src={YOUTUBE_PLAY_ICON}
            style={{ marginLeft: -36, marginTop: -30 }}
          />
        </Button>
      </div>

      <Modal
        centered
        isOpen={modalOpen}
        onToggle={toggleModalOpen}
        size='lg'
      >
        <ModalHeader onToggle={toggleModalOpen} />
        <ModalBody>
          <ContentstackYoutubeVideoBase
            data={data}
            maxWidth={maxWidth}
            autoFocus={autoFocus}
          />
        </ModalBody>
        <ModalHeader />
      </Modal>
    </>
  )
}

export {
  ContentstackYoutubeVideoModal
}
