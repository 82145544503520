import { type ReactElement } from 'react'
import { type SimpleImageWithLinkInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import clsx from 'clsx'
import Link from 'next/link'

interface ContentstackSimpleImageWithLinkProps {
  data?: SimpleImageWithLinkInfoFragment | null
  /**
   * Optional class name which can be set by other components when reusing the Simple Image with Link component
   */
  className?: string
}

/**
 * The Simple Image with Link contains the Simple Image that acts as a link when a user clicks on it.
 */
function ContentstackSimpleImageWithLink ({ data, className }: ContentstackSimpleImageWithLinkProps): ReactElement | null {
  const classNames = clsx('img-fluid', className)
  const {
    alt_text: altText,
    image_source: imageSource,
    link
  } = data ?? {}

  if (isSimpleImageEmpty(data)) {
    return null
  }

  return (
    <Link
      href={link?.href ?? ''}
      title={link?.title ?? altText ?? imageSource ?? ''}
      data-gtm-click-text={link?.title ?? altText ?? imageSource ?? ''}
    >
      <img
        alt={altText ?? ''}
        className={classNames}
        src={imageSource ?? ''}
      />
    </Link>
  )
}

export {
  ContentstackSimpleImageWithLink
}
