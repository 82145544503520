import { type ReactElement } from 'react'
import { type BlogCardFeaturedImage } from './types'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'

interface ContentstackBlogCardFeaturedImageProps {
  data: BlogCardFeaturedImage
}

/**
 * Renders the featured image of a ContentstackBlogCardGroup
 * @memberof ContentstackBlogCardGroup
 */
function ContentstackBlogCardFeaturedImage ({ data }: ContentstackBlogCardFeaturedImageProps): ReactElement | null {
  if (isSimpleImageEmpty(data)) {
    return null
  }

  return (
    <div className='col-12'>
      <ContentstackSimpleImage
        className='img-fluid rounded object-fit-cover h-100 w-100'
        data={data}
      />
      <style jsx>{`
        div {
          height: 250px;

          @media (min-width: ${variables.breakpointMd}) {
            height: 150px;
          }

          @media (min-width: ${variables.breakpointXl}) {
            height: 250px;
          }
        }
      `}
      </style>
    </div>
  )
}

export {
  ContentstackBlogCardFeaturedImage
}
