import { ContentstackMediaBoxBody } from './ContentstackMediaBoxBody'
import { ContentstackMediaBoxHeader } from './ContentstackMediaBoxHeader'
import { ContentstackMediaBoxMedia } from './ContentstackMediaBoxMedia'
import { isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { type MediaBox } from './types'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackMediaBoxProps {
  data?: MediaBox
  /**
   * determines column count of  Media Boxes
   */
  columnClass?: string
}

/**
 * Inidividual  Media Box inside of a ContentstackMediaGoxGroup
 * @memberof ContentstackMediaGoxGroup
 */
function ContentstackMediaBox ({
  data,
  columnClass
}: ContentstackMediaBoxProps): ReactElement | null {
  const {
    title,
    header,
    body,
    media,
    appearance,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const isHeaderEmpty = isRteEmpty(header?.heading?.json)
  const isBodyEmpty = isRteEmpty(body?.rich_text?.json)
  const isFullImageEmpty = isSimpleImageEmpty(media?.full_image)
  const isPortraitImageEmpty = isSimpleImageEmpty(media?.portrait_image)
  const isVideoEmpty = isYoutubeVideoEmpty(media?.video)
  const isMediaEmpty = isFullImageEmpty && isPortraitImageEmpty && isVideoEmpty

  if (isHeaderEmpty && isBodyEmpty && isMediaEmpty) {
    return null
  }

  const rowClassNames = clsx(
    'row gy-3 justify-content-center gx-3 gx-md-4',
    appearance?.text_position === 'left' && 'flex-row-reverse'
  )
  const mediaBoxContentClassNames = clsx(
    'col-12 col-md',
    appearance?.text_position === 'center' ? 'text-center' : 'text-center text-md-start'
  )

  return (
    <div className={columnClass} data-gtm-click-section='card' data-gtm-click-subsection1={title}>
      <div className={rowClassNames}>
        <ContentstackMediaBoxMedia data={media} />
        {(!isHeaderEmpty || !isBodyEmpty) && (
          <div className={mediaBoxContentClassNames}>
            <ContentstackMediaBoxHeader
              data={header}
            />
            <ContentstackMediaBoxBody
              data={body}
              title={title}
              buttonOrientation={appearance?.button_orientation}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export {
  ContentstackMediaBox
}
