import { Button, type ButtonProps } from '@stuller/stullercom/ui'
import { type ContentstackButtonProps } from './ContentstackButton'
import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { forwardRef } from 'react'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackButtonBaseProps extends ContentstackButtonProps {
  href?: string
  onClick?: ButtonProps['onClick']
}

/**
 * Used to map appearance.outline to ButtonProps['outline']
 */
const outlineMap: Record<string, string | boolean> = {
  fill: 'fill',
  normal: true
}

const ContentstackButtonBase = forwardRef<HTMLButtonElement | HTMLAnchorElement, ContentstackButtonBaseProps>(({
  data,
  href,
  onClick,
  ...otherAttributes
}, ref) => {
  const {
    appearance,
    icon_left: iconLeft,
    icon_right: iconRight,
    link,
    text
  } = data ?? {}
  const color = appearance?.color != null ? appearance.color as ButtonProps['color'] : undefined
  const customColor = !isStringEmpty(appearance?.custom_color) ? appearance?.custom_color : undefined
  const json = text?.json
  const outline = outlineMap[appearance?.outline ?? ''] as ButtonProps['outline']
  const pill = appearance?.pill != null ? appearance.pill as ButtonProps['pill'] : undefined
  const size = appearance?.size != null ? appearance.size as ButtonProps['size'] : undefined
  const tag = isStringEmpty(link?.url?.href) ? 'button' : 'a'
  const target = link?.open_in_new_tab === true ? '_blank' : '_self'
  const title = link?.url?.title != null ? link?.url?.title : ''

  if (isRteEmpty(json)) {
    return null
  }

  return (
    <Button
      aria-label={title}
      close={false}
      color={color}
      customColor={customColor}
      onClick={onClick}
      outline={outline}
      href={href}
      pill={pill}
      ref={ref}
      size={size}
      tag={tag}
      target={target}
      {...otherAttributes}
    >
      <ContentstackIcon
        icon={iconLeft}
        className='me-3 fs-4'
      />
      <ContentstackJsonRte
        json={json}
        excludeOuterTags
      />
      <ContentstackIcon
        icon={iconRight}
        className='ms-3 fs-4'
      />
    </Button>
  )
})
ContentstackButtonBase.displayName = 'ContentstackButtonBase'

export {
  ContentstackButtonBase
}
