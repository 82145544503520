import { Card, CardBody } from '@stuller/stullercom/ui'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { type ReactElement } from 'react'
import { type TestimonialsInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'

interface ContentstackTestimonialGroupProps {
  data?: NonNullable<TestimonialsInfoFragment['testimonials']>[0]
  index?: number
}

function ContentstackTestimonial ({ data, index }: ContentstackTestimonialGroupProps): ReactElement | null {
  const {
    title,
    portrait_image: portraitImage,
    quote,
    source_description: sourceDescription,
    source_name: sourceName,
    targetingConnection
  } = data ?? {}

  const isDescriptionEmpty = isRteEmpty(sourceDescription?.json)
  const isNameEmpty = isRteEmpty(sourceName?.json)
  const isQuoteEmpty = isRteEmpty(quote?.json)
  const isImageEmpty = isSimpleImageEmpty(portraitImage)
  const portraitImageSize: number = 80

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  return (
    <Card
      className='h-100 w-100 rounded-3 border shadow-sm text-center'
      data-gtm-click-section='card'
      data-gtm-click-subsection1={title}
      data-gtm-click-subsection1-index={index}
    >
      <CardBody className='h-100 p-3 p-sm-4 p-md-6 d-flex flex-column flex-nowrap align-items-start justify-content-center'>
        {!isImageEmpty && (
          <div className='w-100 d-flex justify-content-center mb-4'>
            <ContentstackSimpleImage
              data={portraitImage}
              className='object-fit-contain rounded-circle'
              style={{ height: portraitImageSize, width: portraitImageSize }}
            />
          </div>
        )}
        {!isQuoteEmpty && (
          <blockquote className='w-100 position-relative mb-0'>
            <FontAwesomeIcon icon={fas.faQuoteLeft} className='position-absolute z-0 top-0 start-0' size='2xl' color='#DDDDDD' />
            <div className='w-100 px-5 px-sm-6 px-md-7 position-relative'>
              <p className='h2 fs-2 fw-bold'>
                <ContentstackJsonRte excludeOuterTags json={quote?.json} />
              </p>
            </div>
            <FontAwesomeIcon icon={fas.faQuoteRight} className='position-absolute z-0 bottom-0 end-0' size='2xl' color='#DDDDDD' />
          </blockquote>
        )}
        {!isNameEmpty && (
          <div className='fs-3 fw-bold w-100 mt-4'>
            <ContentstackJsonRte json={sourceName?.json} />
          </div>
        )}
        {!isDescriptionEmpty && (
          <div className='fs-4 text-gray-600 w-100 mt-2'>
            <ContentstackJsonRte json={sourceDescription?.json} />
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export {
  ContentstackTestimonial
}
