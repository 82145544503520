import { type ReactElement } from 'react'
import { type CardGroupCardBody } from './types'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackBulletList, isBulletListEmpty } from '../contentstack-bullet-list/ContentstackBulletList'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import clsx from 'clsx'
import { isArrayEmpty } from '@stuller/shared/util/core'

interface ContentstackCardBodyProps {
  data: CardGroupCardBody
  alignmentClass: string
}

/**
 * Renders the body text and buttons inside of a ContentstackCard component
 * @memberof ContentstackCardGroup
 */
function ContentstackCardBody ({ data, alignmentClass }: ContentstackCardBodyProps): ReactElement | null {
  const {
    rich_text: richText,
    bullet_listConnection: bulletListConnection,
    buttons
  } = data ?? {}

  const bulletList = bulletListConnection?.edges?.[0]?.node
  const emptyBulletList = isBulletListEmpty(bulletList)
  const emptyRichText = isRteEmpty(richText?.json)
  const emptyButtons = isArrayEmpty(buttons)

  if (emptyRichText && emptyBulletList && emptyButtons) {
    return null
  }

  const bodyClasses = clsx('row gy-3', alignmentClass)
  const buttonClasses = clsx(
    'row gx-4 gy-3',
    alignmentClass === 'text-center' ? 'justify-content-center' : 'justify-content-start'
  )

  return (
    <div
      className='col-12'
    >
      <div className={bodyClasses}>
        {!emptyRichText && (
          <div className='col-12 mb-n3'>
            <ContentstackJsonRte json={richText?.json ?? []} />
          </div>)}
        {!emptyBulletList && (
          <div className='col-12'>
            <ContentstackBulletList data={bulletList} />
          </div>)}
        {!emptyButtons && (
          <div className='col-12 gy-4'>
            <div className={buttonClasses}>
              {buttons?.map((button, i) => !isButtonEmpty(button) && (
                <div
                  className='col-auto'
                  key={i}
                >
                  <ContentstackButton
                    data={button}
                    data-gtm-click-subsection2={`${i > 0 ? 'secondary' : 'primary'}-cta`}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {
  ContentstackCardBody
}
