import { type ReactElement } from 'react'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { type PageHeaderBody } from './types'
import clsx from 'clsx'
import { isArrayEmpty } from '@stuller/shared/util/core'

interface ContentstackPageHeaderBodyProps {
  data: PageHeaderBody
  buttonOrientation?: string | null
  textPosition?: string | null
}

/**
 * Main content of Page Header.
 */
function ContentstackPageHeaderBody ({ data, buttonOrientation, textPosition }: ContentstackPageHeaderBodyProps): ReactElement | null {
  const {
    rich_text: richText,
    buttons
  } = data ?? {}

  const hasEmptyButtons = isArrayEmpty(buttons)
  const hasEmptyRichText = isRteEmpty(richText?.json)

  if (hasEmptyButtons && hasEmptyRichText) {
    return null
  }

  // button row position of stacked or side-by-side
  const buttonRowClasses = clsx(
    'row gx-3 gy-4',
    buttonOrientation === 'vertical' && 'flex-column gy-md-4 mt-n4',
    textPosition === 'center' && 'justify-content-center'
  )

  return (
    <>
      {!hasEmptyRichText && (
        <div className='mt-3 mb-4'>
          <ContentstackJsonRte json={richText?.json} />
        </div>
      )}
      {!hasEmptyButtons && (
        <div className={buttonRowClasses}>
          {buttons?.map((button, index) => !isButtonEmpty(button) && (
            <div
              key={index}
              className='col-auto'
            >
              <ContentstackButton
                data={button}
                data-gtm-click-subsection2={`${index > 0 ? 'secondary' : 'primary'}-cta`}
              />
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export {
  ContentstackPageHeaderBody
}
