import { type ReactElement } from 'react'
import { type BreadcrumbsInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { Breadcrumb, BreadcrumbItem } from '@stuller/stullercom/ui'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackBreadcrumbsProps {
  data?: BreadcrumbsInfoFragment | null
  currentPage?: string | null
}

/**
 * Component that renders a sticky navigation bar with links that scroll to specific sections of a content page
 */
function ContentstackBreadcrumbs ({ data, currentPage }: ContentstackBreadcrumbsProps): ReactElement | null {
  const breadcrumbs = data?.breadcrumbConnection?.edges
  const auth = useAuth()
  const { isShowcase } = auth

  if (isShowcase || isStringEmpty(currentPage)) {
    return null
  }

  return (
    <div className='container-xxxl px-4 mw-xxl d-none d-sm-block'>
      <Breadcrumb>
        <BreadcrumbItem href='/'>
          Home
        </BreadcrumbItem>
        {breadcrumbs?.map((breadcrumb) => !isStringEmpty(breadcrumb?.node?.url) && (
          <BreadcrumbItem key={breadcrumb.node?.system?.uid} href={breadcrumb?.node?.url}>
            {breadcrumb?.node?.title}
          </BreadcrumbItem>
        ))}
        <BreadcrumbItem active>
          {currentPage}
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  )
}

export {
  ContentstackBreadcrumbs
}
