import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type ExpandableImageGroupInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { ContentstackExpandableImage } from './ContentstackExpandableImage'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import clsx from 'clsx'

interface ContentstackExpandableImageGroupProps {
  data?: ExpandableImageGroupInfoFragment | null
}

/**
 * A component containing a group of up to 4 expandable images
 */
function ContentstackExpandableImageGroup ({ data }: ContentstackExpandableImageGroupProps): ReactElement | null {
  const {
    title,
    expandable_images: expandableImages,
    section_header: sectionHeader,
    appearance,
    targetingConnection
  } = data ?? {}

  const spacing = useContentstackSpacing('height', 'min', appearance?.min_height)
  const background = useContentstackBackground(appearance?.background)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isExpandableImagesEmpty = isArrayEmpty(expandableImages)
  if (!showContent || isExpandableImagesEmpty) {
    return null
  }

  const sectionClassNames = clsx(
    'mw-xxxl m-auto overflow-hidden',
    background.className
  )

  return (
    <section
      className={sectionClassNames}
      id={contentstackTitleToHtmlId(title)}
      data-gtm-click-section='topic'
      data-gtm-click-subsection1={title}
    >
      <div className='container-xxxl mw-xxl px-4 py-6 py-md-8'>
        <div className='row gy-5'>
          <ContentstackSectionHeader data={sectionHeader} />
          <div className='d-flex flex-column flex-lg-row justify-content-center'>
            {expandableImages?.map((data, index) => (
              <ContentstackExpandableImage
                key={index}
                data={data}
                groupTitle={title}
                spacing={spacing}
              />
            ))}
          </div>
        </div>
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackExpandableImageGroup
}
