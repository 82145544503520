import { type ReactElement } from 'react'
import { type VerticalMediaBoxHeader } from './types'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ProductBadge, type ProductBadgeType } from '@stuller/stullercom/ui'
import clsx from 'clsx'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackVerticalMediaBoxHeaderProps {
  data: VerticalMediaBoxHeader
  /**
   * Determines left or center aligned text
   */
  alignmentClass: string
}

/**
 * Renders the heading, subheading, and badge of a ContentstackVerticalMediaBox
 * @memberof ContentstackVerticalMediaGoxGroup
 */
function ContentstackVerticalMediaBoxHeader ({ data, alignmentClass }: ContentstackVerticalMediaBoxHeaderProps): ReactElement | null {
  const { heading, subheading, badge } = data ?? {}
  const emptyHeadingJson = isRteEmpty(heading?.json)
  const emptySubheadingJson = isRteEmpty(subheading?.json)
  const emptyBadge = isStringEmpty(badge)

  if (emptyHeadingJson && emptySubheadingJson && emptyBadge) {
    return null
  }

  const headerClasses = clsx(
    alignmentClass,
    'col-12 mb-n3'
  )

  return (
    <div className={headerClasses}>
      {!emptyBadge && (
        <div className='mb-3'>
          <ProductBadge type={badge as ProductBadgeType} />
        </div>)}
      {!emptyHeadingJson && (
        <h3>
          <ContentstackJsonRte excludeOuterTags json={heading?.json} />
        </h3>)}
      {!emptySubheadingJson && (
        <h4 className='fw-normal mt-n3'>
          <ContentstackJsonRte excludeOuterTags json={subheading?.json} />
        </h4>)}
    </div>
  )
}

export {
  ContentstackVerticalMediaBoxHeader
}
