import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { isArrayEmpty, isStringEmpty } from '@stuller/shared/util/core'
import { type ReactElement } from 'react'
import { type SplitTopicTopic } from './types'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackSplitTopicTopicProps {
  data?: SplitTopicTopic
}

function ContentstackSplitTopicTopic ({ data }: ContentstackSplitTopicTopicProps): ReactElement | null {
  const {
    title,
    appearance,
    buttons,
    header,
    targetingConnection
  } = data ?? {}

  const {
    image_xs: imageXs,
    image_sm: imageSm,
    image_md: imageMd,
    image_lg: imageLg,
    image_xl: imageXl,
    image_xxl: imageXxl,
    image_xxxl: imageXxxl
  } = appearance?.background_image?.image_sources ?? {}

  const hasBackgroundImage = (
    !isStringEmpty(imageXs) ||
    !isStringEmpty(imageSm) ||
    !isStringEmpty(imageMd) ||
    !isStringEmpty(imageLg) ||
    !isStringEmpty(imageXl) ||
    !isStringEmpty(imageXxl) ||
    !isStringEmpty(imageXxxl)
  )

  const background = useContentstackBackground(null, appearance?.background_image)
  const isHeadingEmpty = isRteEmpty(header?.heading?.json)
  const isSubHeadingEmpty = isRteEmpty(header?.subheading?.json)

  const topicClassNames = clsx(
    'text-center h-100 d-flex flex-column justify-content-end',
    hasBackgroundImage && `text-${appearance?.text_color}`,
    background.className
  )

  const overlayClassNames = clsx(
    'overlay bg-glass-blur',
    hasBackgroundImage && (appearance?.text_color === 'body' ? 'bg-glass-light' : 'bg-glass-dark')
  )
  const headingClassNames = clsx(
    'mb-0',
    appearance?.heading_font_size ?? ''
  )

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || isHeadingEmpty) {
    return null
  }

  return (
    <div
      className={topicClassNames}
      data-gtm-click-section='card'
      data-gtm-click-subsection1={title}
    >
      <div className={overlayClassNames}>
        <div className='py-5 px-4 mt-auto'>
          <div className='row gy-5'>
            {!isHeadingEmpty && (
              <div className='col-12'>
                {!isSubHeadingEmpty && (
                  <p className='mb-0'>
                    <ContentstackJsonRte excludeOuterTags json={header?.subheading?.json} />
                  </p>
                )}
                <h2 className={headingClassNames}>
                  <ContentstackJsonRte excludeOuterTags json={header?.heading?.json} />
                </h2>
              </div>
            )}
            {!isArrayEmpty(buttons) && (
              <div className='col-12'>
                <div className='d-flex h-100 justify-content-center align-items-end'>
                  <div className='row gy-3 justify-content-center'>
                    {buttons?.map((button, i) => !isButtonEmpty(button) && (
                      <div className='col-auto' key={i}>
                        <ContentstackButton
                          data={button}
                          data-gtm-click-subsection2={`${i > 0 ? 'secondary' : 'primary'}-cta`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {background.styles}
    </div>
  )
}

export {
  ContentstackSplitTopicTopic
}
