import { type ReactElement } from 'react'
import clsx from 'clsx'
import type { SocialIconsFeatureInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import Link from 'next/link'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackSocialIconProps {
  socialMediaIcon?: NonNullable<SocialIconsFeatureInfoFragment['social_media_icons']>[0]
  /**
   * Color of icon that is also used to determine the color of the circle around the icon
   */
  iconColor?: string
}

/**
 * Individual Social Icon rendered inside <ContentstackSocialIconsFeature />
 * @memberof ContentstackSocialIconsFeature
 */
function ContentstackSocialIcon ({ socialMediaIcon, iconColor }: ContentstackSocialIconProps): ReactElement | null {
  const {
    icon,
    title,
    link
  } = socialMediaIcon ?? {}

  if (isStringEmpty(socialMediaIcon?.icon)) {
    return null
  }

  const cmsIconCircleSize: number = 50
  const wrapperClasses = clsx(
    'd-flex flex-column align-items-center justify-content-center rounded-circle',
    iconColor === 'blue-500' ? 'bg-white' : 'bg-blue-500',
    `text-${iconColor}`
  )

  return (
    <div className='col-auto'>
      <Link
        href={link?.href ?? ''}
        title={link?.title ?? ''}
        className={wrapperClasses}
        style={{
          maxHeight: cmsIconCircleSize,
          maxWidth: cmsIconCircleSize,
          minHeight: cmsIconCircleSize,
          minWidth: cmsIconCircleSize
        }}
        data-gtm-click-text={title}
      >
        <ContentstackIcon
          icon={icon}
          size='2x'
          defaultIcon='fas-link'
        />
      </Link>
    </div>
  )
}

export {
  ContentstackSocialIcon
}
