import { ContentstackVerticalMediaBoxBody } from './ContentstackVerticalMediaBoxBody'
import { ContentstackVerticalMediaBoxHeader } from './ContentstackVerticalMediaBoxHeader'
import { ContentstackVerticalMediaBoxMedia } from './ContentstackVerticalMediaBoxMedia'
import { isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { type ReactElement } from 'react'
import { type VerticalMediaBox } from './types'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'
import { isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackVerticalMediaBoxGroupProps {
  data: VerticalMediaBox
  /**
   * Determines left or center aligned text
   */
  alignmentClass: string
  /**
   * determines column count of Vertical Media Boxes
   */
  columnClass: string | false
}

/**
 * Inidividual Vertical Media Box inside of a ContentstackVerticalMediaGoxGroup
 * @memberof ContentstackVerticalMediaGoxGroup
 */
function ContentstackVerticalMediaBox ({
  data,
  alignmentClass,
  columnClass
}: ContentstackVerticalMediaBoxGroupProps): ReactElement | null {
  const {
    title,
    header,
    body,
    media,
    targetingConnection
  } = data ?? {}

  const isHeadingEmpty = isRteEmpty(header?.heading?.json)
  const isBodyRichTextEmpty = isRteEmpty(body?.rich_text?.json)
  const isFullImageEmpty = isSimpleImageEmpty(media?.full_image)
  const isPortraitImageEmpty = isSimpleImageEmpty(media?.portrait_image)
  const isVideoEmpty = isYoutubeVideoEmpty(media?.youtube_video)
  const isIconEmpty = isStringEmpty(media?.icon)
  const isMediaEmpty = isFullImageEmpty && isPortraitImageEmpty && isVideoEmpty && isIconEmpty

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  if (isHeadingEmpty && isBodyRichTextEmpty && isMediaEmpty) {
    return null
  }

  const verticalMediaBoxClasses = clsx(
    alignmentClass,
    columnClass
  )

  return (
    <div className={verticalMediaBoxClasses} data-gtm-click-section='card' data-gtm-click-subsection1={title}>
      <div className='row gy-3'>
        <ContentstackVerticalMediaBoxMedia data={media} />
        <ContentstackVerticalMediaBoxHeader
          alignmentClass={alignmentClass}
          data={header}
        />
        <ContentstackVerticalMediaBoxBody
          alignmentClass={alignmentClass}
          data={body}
        />
      </div>
    </div>
  )
}

export {
  ContentstackVerticalMediaBox
}
