import { ContentstackButtonBase } from './ContentstackButtonBase'
import { type ContentstackButtonProps } from './ContentstackButton'
import { ContentstackModal } from '../contentstack-modal/ContentstackModal'
import { type ReactElement } from 'react'
import { useToggle } from '@stuller/shared/util/react-hooks'

/**
 * An instance of the button with an included modal component
 */
function ContentstackButtonModal ({ data, ...otherAttributes }: ContentstackButtonProps): ReactElement {
  const [modalOpen, toggleModalOpen] = useToggle()
  const modalData = data?.modalConnection?.edges?.[0]?.node

  return (
    <>
      <ContentstackButtonBase
        data={{ ...data, link: null }}
        onClick={toggleModalOpen}
        {...otherAttributes}
      />

      <ContentstackModal
        data={modalData}
        modalOpen={modalOpen}
        toggleModalOpen={toggleModalOpen}
      />
    </>
  )
}

export {
  ContentstackButtonModal
}
