import { type ReactNode } from 'react'
import Link from 'next/link'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IMAGE_COMING_SOON } from '@stuller/shared/util/constants'
import { isStringEmpty } from '@stuller/shared/util/core'
import { type ProductData } from '@stuller/stullercom/ui'

interface ContentstackShoppableDotPopupContentProps {
  productOrGroupNumber?: string
  data?: ProductData | null
}

/**
 * Information inside of the shoppable dot popup for products and product groups
 */
function ContentstackShoppableDotProductContent ({ productOrGroupNumber, data }: ContentstackShoppableDotPopupContentProps): ReactNode | null {
  if (isStringEmpty(productOrGroupNumber) || data == null) {
    return null
  }

  return (
    <div className='row align-items-center m-0 p-0 fs-5'>
      <div className='col-auto p-3 m-0 bg-white' style={{ height: 130, minWidth: 130 }}>
        <img src={data.image ?? IMAGE_COMING_SOON} className='bg-white h-100' />
      </div>
      <div className='p-3 col row d-flex align-items-center'>
        <Link
          target='_blank'
          href={data.url}
          className='d-block stretched-link'
          data-gtm-click-subsection2='shoppable-products'
          data-gtm-click-text={data.title}
        >
          {productOrGroupNumber}
        </Link>
        <p>{data?.title}</p>
      </div>
      <div className='col-auto'>
        <FontAwesomeIcon icon={fas.faCaretRight} size='2x' className='text-cyan-300' />
      </div>
    </div>
  )
}

export {
  ContentstackShoppableDotProductContent
}
