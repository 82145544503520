import { ContentstackYoutubeVideoBase } from './ContentstackYoutubeVideoBase'
import { ContentstackYoutubeVideoModal } from './ContentstackYoutubeVideoModal'
import { isStringEmpty } from '@stuller/shared/util/core'
import { type ReactElement } from 'react'
import { type YoutubeVideoInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'

export interface ContentstackYoutubeVideoProps {
  data?: YoutubeVideoInfoFragment | null
  /**
   * Overrides the default 700px
   */
  maxWidth?: string
  /**
   * Displays a clickable image of a YouTube video that opens a modal with the iframe
   */
  modal?: boolean
  /**
   * Allows for autofocusing on the iframe; useful for keyboard control with the modal variant
   */
  autoFocus?: boolean
}

/**
 * Helper function to not render any parent elements when the youtube video is empty
 */
function isYoutubeVideoEmpty (data?: YoutubeVideoInfoFragment | null): boolean {
  return isStringEmpty(data?.video?.id as string)
}

/**
 * A CMS user should be able to add a YouTube video to a page.
 *
 * The video should match designs and have all configurations matching the build kit.
 */
function ContentstackYoutubeVideo ({ data, maxWidth, modal = false, autoFocus }: ContentstackYoutubeVideoProps): ReactElement | null {
  if (isYoutubeVideoEmpty(data)) {
    return null
  }

  if (modal) {
    return <ContentstackYoutubeVideoModal data={data} maxWidth={maxWidth} autoFocus={autoFocus} />
  }

  return <ContentstackYoutubeVideoBase data={data} maxWidth={maxWidth} autoFocus={autoFocus} />
}

export {
  ContentstackYoutubeVideo,
  isYoutubeVideoEmpty
}
