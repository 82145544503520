import { type ReactElement } from 'react'
import { type VerticalMediaBoxMedia } from './types'
import { ContentstackYoutubeVideo, isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackVerticalMediaBoxMediaProps {
  data: VerticalMediaBoxMedia
}

/**
 * Renders video, full image, portrait image, or icons in a ContentStackVerticalMediaBox
 * @memberof ContentstackVerticalMediaBoxMedia
 */
function ContentstackVerticalMediaBoxMedia ({ data }: ContentstackVerticalMediaBoxMediaProps): ReactElement | null {
  const {
    full_image: fullImage,
    icon,
    portrait_image: portraitImage,
    youtube_video: youtubeVideo
  } = data ?? {}

  const isFullImageEmpty = isSimpleImageEmpty(fullImage)
  const isPortraitImageEmpty = isSimpleImageEmpty(portraitImage)
  const isVideoEmpty = isYoutubeVideoEmpty(youtubeVideo)
  const isIconEmpty = isStringEmpty(icon)

  if (isFullImageEmpty && isPortraitImageEmpty && isVideoEmpty && isIconEmpty) {
    return null
  }

  let mediaContent: ReactElement

  if (!isYoutubeVideoEmpty(youtubeVideo)) {
    mediaContent = (
      <ContentstackYoutubeVideo
        data={youtubeVideo}
        modal
      />
    )
  } else if (!isSimpleImageEmpty(fullImage)) {
    mediaContent = (
      <ContentstackSimpleImage
        className='img-fluid rounded'
        data={fullImage}
      />
    )
  } else if (!isSimpleImageEmpty(portraitImage)) {
    mediaContent = (
      <ContentstackSimpleImage
        className='img-fluid rounded-circle'
        data={portraitImage}
      />
    )
  } else if (!isStringEmpty(icon)) {
    mediaContent = <ContentstackIcon icon={icon} size='3x' />
  } else {
    return null
  }

  return (
    <div className='col-12 mb-3'>
      {mediaContent}
    </div>
  )
}

export {
  ContentstackVerticalMediaBoxMedia
}
