import { AccordionBody, AccordionHeader, AccordionItem } from '@stuller/stullercom/ui'
import { ContentstackButton } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { type AccordionAccordionItem } from './types'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'
import { ContentstackAccordionItemMedia } from './ContentstackAccordionItemMedia'

interface ContentstackAccordionItemProps {
  data: AccordionAccordionItem
  /**
   * Index of accordion item in the list
   */
  index: number
  /**
   * True if the accordion item is the last item in the list
   */
  lastItem: boolean
  /**
   * Background color of accordion item headers
   */
  headerColor: string
  /**
   * Layout of the accordion
   */
  layout: string
}

/**
 * Inidividual Accordion Item inside of a ContentstackAccordion
 * @memberof ContentstackAccordion
 */
function ContentstackAccordionItem ({ data, index, lastItem, headerColor, layout }: ContentstackAccordionItemProps): ReactElement | null {
  const {
    body,
    heading,
    targetingConnection,
    title
  } = data ?? {}

  const {
    appearance,
    button,
    media,
    rich_text: richText
  } = body ?? {}

  const hasHeading = !isRteEmpty(heading?.json)
  const hasImage = !isSimpleImageEmpty(media?.image)
  const hasVideo = !isYoutubeVideoEmpty(media?.video)
  const hasRichText = !isRteEmpty(richText?.json)
  const hasButton = !isRteEmpty(button?.text?.json)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  if (!hasHeading && !hasRichText && !hasImage && !hasVideo && !hasButton) {
    return null
  }

  const accordionItemClassNames = clsx(
    lastItem && 'mb-0'
  )

  const bodyClassNames = clsx(
    headerColor === 'bg-white' ? 'border-top border-gray-400' : ''
  )

  const rowClassNames = clsx(
    'row gy-4',
    appearance?.text_position === 'left' && 'flex-md-row-reverse flex-column-reverse',
    appearance?.text_position === 'right' && 'justify-content-end flex-column-reverse flex-md-row'
  )

  const textContainerClassNames = clsx(
    (hasImage || hasVideo) && layout === 'normal' && 'col-md-6'
  )

  const mediaClassNames = clsx(
    layout === 'pop-out-media' && 'd-md-none'
  )

  return (
    <AccordionItem className={accordionItemClassNames} itemId={`${index}`} data-gtm-click-subsection1={title}>
      <AccordionHeader buttonClassName={headerColor}>
        {hasHeading ? <ContentstackJsonRte excludeOuterTags json={heading?.json} /> : ''}
      </AccordionHeader>
      <AccordionBody className={bodyClassNames}>
        <div className={rowClassNames}>
          <ContentstackAccordionItemMedia data={media} className={mediaClassNames} />

          {(hasRichText || hasButton) && (
            <div className={textContainerClassNames}>
              {hasRichText && (
                <div className='col-12'>
                  <ContentstackJsonRte excludeOuterTags json={richText?.json} />
                </div>)}
              {hasButton && (
                <div className='col-12 mt-3'>
                  <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
                </div>)}
            </div>)}
        </div>
      </AccordionBody>
    </AccordionItem>
  )
}

export {
  ContentstackAccordionItem
}
