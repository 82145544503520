import { type ReactElement, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useEventListener } from '@stuller/shared/util/react-hooks'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { trackEvent } from '@stuller/stullercom/feat/google-tag-manager'
import { useGetFormstackContactsQuery } from '@stuller/stullercom/data-access/apollo-queries'
import { isStringEmpty } from '@stuller/shared/util/core'
import { Loading } from '@stuller/stullercom/ui'
import {
  formEvents,
  type FormEventData,
  type FormstackEventDetails
} from './types'

const BASE_FORM_URL = 'https://stuller.formstack.com/forms/js.php'

interface FormstackFormProps {
  /**
   * The name of the form to render
   */
  name: string
}

/**
 * Renders a Formstack form
 *
 * Note that the form will automatically resize based on the content and is default to 100% width
 * Place the form inside a container to control the width
 *
 * Docs:
 * - Live Forms API: https://live-form-api.formstack.com/index.html
 * - Live Forms API Examples: https://help.formstack.com/s/article/V4-Live-Forms-API-Examples
 * - Pre-populating Forms: https://help.formstack.com/s/article/Pre-Populating-Form-Fields
 */
function FormstackForm ({ name }: FormstackFormProps): ReactElement {
  const { authUser } = useAuth()
  const { data, loading } = useGetFormstackContactsQuery({
    skip: authUser?.user?.defaultActiveShipToAccount?.id == null,
    variables: {
      shipToAccountNumber: authUser?.user?.defaultActiveShipToAccount?.id ?? ''
    }
  })
  const [contactOptions, contactMap] = useMemo(() => {
    if (data == null) {
      return [[], {}]
    }

    const contactOptions = data.contactSearch.results.map(c => ({
      label: c.fullName,
      value: c.id
    }))
    const contactMap = data.contactSearch.results.reduce<Record<string, any>>((acc, c) => {
      acc[c.id] = c

      return acc
    }, {})

    return [contactOptions, contactMap]
  }, [data])
  const id = useId()
  const formMessageId = useMemo(() => `formstack-form-${id}`, [id])
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeSrc, setIframeSrc] = useState<string | null>(null)
  const [height, setHeight] = useState(1)
  const [formReadyData, setFormReadyData] = useState<FormstackEventDetails<'ready'>['data'] | null>(null)
  const [formValid, setFormValid] = useState(true)

  // Init the form with listeners, pre-populate fields, and set variables
  useEffect(() => {
    if (isStringEmpty(name) || loading) {
      return
    }

    const formSubmissionId = uuidv4().toString()
    const formUrl = new URL(`${BASE_FORM_URL}/${name}`)
    formUrl.search = new URLSearchParams({
      'form submission id': formSubmissionId,
      'logged in': authUser != null ? 'true' : 'false',
      'member id': authUser?.user?.memberId ?? '',
      'account number': authUser?.user?.defaultActiveShipToAccount?.id ?? ''
    }).toString()

    const newIframeSrc = `
      <html>
        <head>
          <style>
            html, body {
              margin: 0 !important;
              padding: 0 !important;
            }

            form {
              overflow: hidden !important;
            }
          </style>
        </head>
        <body>
          <script>
            function handleOnLoad () {
              let formContainerEl = null
              let formId = null
              let form = null
              const fieldIdMap = {}
              const fieldLabelMap = {}
              const contactOptions = ${JSON.stringify(contactOptions)}
              const contactMap = ${JSON.stringify(contactMap)}

              function init () {
                formContainerEl = document.querySelector('.fsform-container')
                formId = formContainerEl.dataset.formid
                form = window.fsApi().getForm(formId)

                for (const formEvent of ${JSON.stringify(formEvents)}) {
                  form.registerFormEventListener({
                    type: formEvent,
                    onFormEvent: function (event) {
                      let eventInfo = { ...event, preventDefault: undefined }

                      // Add extra info to the ready event
                      if (event.type === 'ready') {
                        formRendered = form.isRendered()

                        eventInfo = {
                          ...eventInfo,
                          data: {
                            ...eventInfo.data,
                            formSubmissionId: '${formSubmissionId}',
                            formRendered,
                            formGenerateLead: window.formGenerateLead ?? true
                          }
                        }

                        // Populate field maps
                        for (const field of formRendered.data.sections.map(s => s.fields).flat()) {
                          const info = { ...field, field: form.getField(field.general.id) }
                          fieldIdMap[field.general.id] = info
                          fieldLabelMap[field.general.label.toLowerCase()] = info
                        }

                        // Update the contact selector accordingly
                        if (fieldLabelMap['contact'] != null) {
                          const contactElm = document.getElementById(\`label-field\${fieldLabelMap['contact']?.general.id}\`)
                          const contactSelectElm = contactElm?.querySelector('select')
                          if (contactOptions.length <= 1) {
                            contactElm.style.display = 'none'
                          }
                          fieldLabelMap['contact']?.field.setGeneralAttribute('options', contactOptions)
                          fieldLabelMap['contact']?.field.setValue({ value: contactOptions[0]?.value ?? '' })
                        }
                      }

                      // Add extra logic to the change event
                      if (event.type === 'change') {
                        // If contact selector changed, update fields needed
                        if (event.data.fieldId === fieldLabelMap['contact']?.general.id) {
                          const contactId = form.getField(fieldLabelMap['contact']?.general.id).getValue().value
                          const contact = contactMap[contactId]

                          form.setValues(Object.entries({
                            [fieldLabelMap['name']?.general.id]: { first: contact?.firstName ?? '', last: contact?.lastName ?? '' },
                            [fieldLabelMap['email']?.general.id]: { value: contact?.emailAddress ?? '' },
                            [fieldLabelMap['mobile phone']?.general.id]: { value: contact?.mobilePhoneNumber?.phoneNumber ?? '' },
                            [fieldLabelMap['salesforce contact id']?.general.id]: { value: contact?.salesforceContactId ?? '' }
                          }).reduce((acc, [k, v]) => k != 'null' && k != 'undefined' ? { ...acc, [k]:v } : acc , {}))
                        }
                      }

                      parent.postMessage({
                        source: '${formMessageId}',
                        type: formEvent,
                        event: eventInfo
                      })

                      return Promise.resolve(event)
                    }
                  })
                }
              }

              function resizeUpdate () {
                // Init if not already
                if (window.fsApi != null && form == null) {
                  init()
                }

                parent.postMessage({
                  source: '${formMessageId}',
                  type: 'resize',
                  height: document.documentElement.offsetHeight + 1
                })
              }

              // Setup resize observer
              resizeUpdate()
              const resizeObserver = new ResizeObserver(resizeUpdate)
              resizeObserver.observe(document.documentElement)
            }
          </script>
          <script
            type='text/javascript'
            src='${formUrl.toString()}'
            onload='handleOnLoad()'
          ></script>
        </body>
      </html>
    `

    setIframeSrc(newIframeSrc)
  }, [authUser, formMessageId, name, loading, contactOptions, contactMap])

  // Listen for messages from the iframe to update the height
  const handleOnMessage = useCallback((event: FormEventData): void => {
    if (event.data.source === formMessageId) {
      switch (event.data.type) {
        case 'resize': {
          setHeight(event.data.height)
          break
        }
        case 'ready': {
          setFormReadyData(event.data.event.data ?? null)
          break
        }
        case 'error': {
          setFormValid(
            Object.values(event.data.event.data?.errors ?? {})
              .map(e => Object.values(e ?? {}))
              .flat()
              .map(e => Object.values(e ?? {}))
              .flat()
              .every(Boolean)
          )
          break
        }
        case 'next-page': {
          trackEvent('form_step_completed', {
            form_submission_id: formReadyData?.formSubmissionId,
            form_name: formReadyData?.formRendered?.config.formResponse.form.name,
            form_step: `${(event.data.event.data?.page ?? 2) - 1}`,
            generate_lead: formReadyData?.formGenerateLead,
            value: 0,
            currency: 'USD'
          })
          break
        }
        case 'submit': {
          if (formValid) {
            trackEvent('form_completed', {
              form_submission_id: formReadyData?.formSubmissionId,
              form_name: formReadyData?.formRendered?.config.formResponse.form.name,
              generate_lead: formReadyData?.formGenerateLead,
              value: 0,
              currency: 'USD'
            })
          }
          break
        }
      }
    }
  }, [formMessageId, formReadyData, formValid])
  useEventListener('message', handleOnMessage)

  return (
    <Loading loading={formReadyData == null}>
      {iframeSrc != null && (
        <iframe
          ref={iframeRef}
          srcDoc={iframeSrc}
          className='w-100'
          style={{
            borderWidth: 0,
            height
          }}
        />)}
    </Loading>
  )
}

export {
  FormstackForm
}
