import { type ReactElement } from 'react'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackYoutubeVideo, isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { type PageHeaderFeaturedMedia } from './types'

interface ContentstackPageHeaderFeaturedMediaProps {
  data: PageHeaderFeaturedMedia
}

/**
 * Featured image or video included in the Page Header.
 */
function ContentstackPageHeaderFeaturedMedia ({ data }: ContentstackPageHeaderFeaturedMediaProps): ReactElement | null {
  const {
    image,
    video
  } = data ?? {}

  let mediaContent: ReactElement
  if (!isYoutubeVideoEmpty(video)) {
    mediaContent = <ContentstackYoutubeVideo data={video} />
  } else if (!isSimpleImageEmpty(image)) {
    mediaContent = <ContentstackSimpleImage data={image} className='w-100' />
  } else {
    return null
  }

  return (
    <div className='col-md-6'>
      <div className='row h-100'>
        <div className='col my-auto d-flex justify-content-center'>
          {mediaContent}
        </div>
      </div>
    </div>
  )
}

export {
  ContentstackPageHeaderFeaturedMedia
}
