import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackYoutubeVideo, isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { type AccordionItemMedia } from './types'
import { type ReactElement } from 'react'
import clsx from 'clsx'

interface ContentstackAccordionItemMediaProps {
  data: AccordionItemMedia
  className?: string
}

/**
 * Media component for the accordion item.
 * @memberof ContentstackAccordion
 * @memberof ContentstackAccordionItem
 */
function ContentstackAccordionItemMedia ({ data, className }: ContentstackAccordionItemMediaProps): ReactElement | null {
  const {
    image,
    video
  } = data ?? {}

  const hasImage = !isSimpleImageEmpty(image)
  const hasVideo = !isYoutubeVideoEmpty(video)

  if (!hasImage && !hasVideo) {
    return null
  }

  const containerClassNames = clsx(
    'col-12 col-md-6',
    className
  )

  return (
    <div className={containerClassNames}>
      {hasVideo && (
        <ContentstackYoutubeVideo data={video} />
      )}

      {hasImage && !hasVideo && (
        <ContentstackSimpleImage
          className='img-fluid rounded-3 object-fit-cover w-100'
          data={image}
        />
      )}
    </div>
  )
}

export {
  ContentstackAccordionItemMedia
}
