import { ContentstackProductCardCarouselInner } from './ContentstackProductCardCarouselInner'
import { type ProductCardCarouselInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'

export interface ContentstackProductCardCarouselProps {
  data?: ProductCardCarouselInfoFragment | null
}

/**
 * A collection of Product Cards that users can swipe through.
 */
function ContentstackProductCardCarousel ({ data }: ContentstackProductCardCarouselProps): ReactElement | null {
  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(data?.targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  return (
    <ContentstackProductCardCarouselInner data={data} />
  )
}

export {
  ContentstackProductCardCarousel
}
