import { useMemo, type ReactElement } from 'react'
import { type ShoppableImageData } from '@stuller/contentstack/util/types'
import { isArrayEmpty, isStringEmpty } from '@stuller/shared/util/core'
import clsx from 'clsx'
import { ContentstackSimpleImage } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackShoppableDot } from './ContententstackShoppableDot'
import { useContentstackRelatedItems } from '../contentstack-related-items/useContentstackRelatedItems'

interface ContentstackShoppableImageProps {
  className: string
  data?: ShoppableImageData | null
  altText?: string | null
  title: string
}

/**
 * Individual Shoppable Repsonsive Images that are displayed based on the user's screen size
 */
function ContentstackShoppableImage ({ data, className, altText, title }: ContentstackShoppableImageProps): ReactElement | null {
  const {
    dots,
    url
  } = data ?? {}
  const shoppableImageClassName = clsx(
    className,
    'position-relative'
  )
  const imageData = {
    image_source: url,
    alt_text: altText
  }
  const shoppableProducts = useMemo(() => {
    return dots?.map((data) => data.content?.type !== 'TEXT' ? data.content : null).filter(data => data != null) ?? []
  }, [dots])

  const { products } = useContentstackRelatedItems({ relatedItems: shoppableProducts, relatedItemsSource: `featureditems-dots-${title}` })
  if (isStringEmpty(url) && isArrayEmpty(dots)) {
    return null
  }

  const productData = dots?.map((dot => {
    const id = dot.content?.type === 'PRODUCT_GROUP' ? dot.content.groupId : dot.content?.type === 'PRODUCT' ? dot.content.productId : null
    switch (dot.content?.type) {
      case 'TEXT':
        return null
      case 'PRODUCT':
      case 'PRODUCT_GROUP':
        return products.find(product => id === product.id)
      default:
        return null
    }
  }))

  return (
    <div className={shoppableImageClassName}>
      <div className='d-inline-block position-relative'>
        <ContentstackSimpleImage data={imageData} />
        <div className='top-0 start-0 position-absolute overflow-visible w-100 h-100'>
          {dots?.map((data, index) => data.content != null && (
            <ContentstackShoppableDot key={index} data={data} productData={productData?.[index]} />
          ))}
        </div>
      </div>
    </div>
  )
}

export {
  ContentstackShoppableImage
}
