import { type ReactElement } from 'react'
import { type BlogCardBody } from './types'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'

interface ContentstackBlogCardBodyProps {
  data: BlogCardBody
}

/**
 * Renders the rich text of a ContentstackBlogCardGroup
 * @memberof ContentstackBlogCardGroup
 */
function ContentstackBlogCardBody ({ data }: ContentstackBlogCardBodyProps): ReactElement | null {
  const {
    rich_text: richText
  } = data ?? {}

  if (isRteEmpty(richText?.json)) {
    return null
  }

  return (
    <div className='col-12 mb-n3'>
      <ContentstackJsonRte json={richText?.json} />
      <style jsx>{`
        div :global(p) {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      `}
      </style>
    </div>
  )
}

export {
  ContentstackBlogCardBody
}
