import { type ReactElement } from 'react'
import { type LargeShoppingBucket } from './types'
import { Card } from '@stuller/stullercom/ui'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackLargeShoppingBucketCardBadge } from './ContentstackLargeShoppingBucketCardBadge'
import { ContentstackLargeShoppingBucketHeader } from './ContentstackLargeShoppingBucketHeader'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackLargeShoppingBucketProps {
  data: LargeShoppingBucket
  title?: string | null
}

/**
 * Component for individual large shopping buckets that are displayed as a group on category pages
 */
function ContentstackLargeShoppingBucket ({ data, title }: ContentstackLargeShoppingBucketProps): ReactElement | null {
  const {
    title: bucketTitle,
    header,
    image,
    appearance,
    body,
    link,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const isProductBadge = appearance?.badge_card ?? false
  const isHeaderEmpty = isRteEmpty(header?.heading?.json)
  const isBodyEmpty = isRteEmpty(body?.rich_text?.json)
  const isImageEmpty = isSimpleImageEmpty(image)

  if (isHeaderEmpty && isBodyEmpty && isImageEmpty) {
    return null
  }

  const cardImageClassNames = clsx(
    'overflow-hidden d-flex',
    appearance?.image_alignment === 'center' ? 'justify-content-center' : appearance?.image_alignment === 'right' ? 'justify-content-end' : 'justify-content-start',
    appearance?.image_padding === true && 'p-4 pb-0'
  )
  const subheaderClassName = clsx(
    appearance?.subheading_text_color === 'blue-400' && 'text-blue-400'
  )
  const shoppingBucketContentClassNames = clsx(
    'flex-fill d-flex flex-wrap p-4',
    isImageEmpty && !isProductBadge && 'align-items-center justify-content-center'
  )
  const linkClassName = clsx(
    isImageEmpty && !isProductBadge && 'justify-content-center text-center'
  )

  return (
    <div
      className='contentstack-large-shopping-bucket col-6 col-md-4 col-lg-3 rounded-3'
      style={{ height: 315 }}
      data-gtm-click-section='bucket'
      data-gtm-click-text={bucketTitle}
    >
      <Card className='border-gray-700-hover overflow-hidden h-100'>
        <div className='d-flex flex-column overflow-hidden h-100'>
          {isProductBadge && <ContentstackLargeShoppingBucketCardBadge data={header?.badge} />}
          {!isProductBadge && !isImageEmpty && (
            <div className={cardImageClassNames} style={{ maxHeight: 250 }}>
              <ContentstackSimpleImage data={image} className='h-100 object-fit-contain' />
            </div>
          )}
          <div className={shoppingBucketContentClassNames}>
            <ContentstackLargeShoppingBucketHeader
              data={header}
              isProductBadge={isProductBadge}
              subheaderClassName={subheaderClassName}
              link={link}
              linkClassName={linkClassName}
              title={title}
            />
            {!isBodyEmpty && (
              <p className='m-0 col-12'>
                <ContentstackJsonRte
                  json={body?.rich_text?.json}
                  excludeOuterTags
                />
              </p>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export {
  ContentstackLargeShoppingBucket
}
