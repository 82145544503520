import { type ReactElement } from 'react'
import { ContentstackYoutubeVideo, isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import type { MediaBoxMedia } from './types'

interface ContentstackMediaBoxMediaProps {
  data?: MediaBoxMedia
}

/**
 * Renders video, full image, portrait image, or icons in a ContentStackMediaBox
 * @memberof ContentstackMediaBoxMedia
 */
function ContentstackMediaBoxMedia ({ data }: ContentstackMediaBoxMediaProps): ReactElement | null {
  const {
    full_image: fullImage,
    portrait_image: portraitImage,
    video: youtubeVideo
  } = data ?? {}

  if (isSimpleImageEmpty(fullImage) && isSimpleImageEmpty(portraitImage) && isYoutubeVideoEmpty(youtubeVideo)) {
    return null
  }

  if (!isYoutubeVideoEmpty(youtubeVideo)) {
    return (
      <div className='col-12 col-md-6'>
        <ContentstackYoutubeVideo data={youtubeVideo} />
      </div>
    )
  } else if (!isSimpleImageEmpty(fullImage)) {
    return (
      <div className='col-12 col-md-6'>
        <ContentstackSimpleImage
          className='rounded d-block m-auto'
          data={fullImage}
        />
      </div>
    )
  } else if (!isSimpleImageEmpty(portraitImage)) {
    return (
      <div className='col-auto'>
        <div className='overflow-hidden rounded-circle' style={{ width: 150, height: 150 }}>
          <ContentstackSimpleImage
            className='w-100 h-100 object-fit-cover'
            data={portraitImage}
          />
        </div>
      </div>
    )
  }

  return null
}

export {
  ContentstackMediaBoxMedia
}
