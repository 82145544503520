import { type ReactElement } from 'react'
import { type BlogCardHeader } from './types'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ProductBadge, type ProductBadgeType } from '@stuller/stullercom/ui'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackBlogCardHeaderProps {
  data: BlogCardHeader
}

/**
 * Renders the heading, subheading, and badge of a ContentstackBlogCardGroup
 * @memberof ContentstackBlogCardGroup
 */
function ContentstackBlogCardHeader ({ data }: ContentstackBlogCardHeaderProps): ReactElement | null {
  const {
    heading,
    subheading,
    badge
  } = data ?? {}
  const emptyHeadingJson = isRteEmpty(heading?.json)
  const emptySubheadingJson = isRteEmpty(subheading?.json)
  const emptyBadge = isStringEmpty(badge)

  if (emptyHeadingJson && emptySubheadingJson && emptyBadge) {
    return null
  }

  return (
    <div className='col-12 mb-n3'>
      {!emptyBadge && (
        <div className='mb-3'>
          <ProductBadge type={badge as ProductBadgeType} />
        </div>)}
      {!emptyHeadingJson && (
        <h2>
          <ContentstackJsonRte excludeOuterTags json={heading?.json} />
        </h2>)}
      {!emptySubheadingJson && (
        <h4 className='fw-normal mt-n3'>
          <ContentstackJsonRte excludeOuterTags json={subheading?.json} />
        </h4>)}
      <style jsx>{`
        h2 {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      `}
      </style>
    </div>
  )
}

export {
  ContentstackBlogCardHeader
}
