import { type ReactElement } from 'react'
import clsx from 'clsx'
import { ModalHeader } from '@stuller/stullercom/ui'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { type ContentstackModalProps } from './ContentstackModal'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'

/**
 * Contains the important information that helps users understand the structure and find what they are looking for
 */
function ContentstackModalHeader ({ data, toggleModalOpen }: ContentstackModalProps): ReactElement {
  const { header } = data ?? {}
  const hasEmptyImage = isSimpleImageEmpty(header?.image)
  const hasEmptySubheading = isRteEmpty(header?.subheading?.json)
  const hasEmptyHeading = isRteEmpty(header?.heading?.json)
  const headingClassNames = clsx('modal-title', hasEmptyImage && 'pt-0')

  return (
    <ModalHeader
      onToggle={toggleModalOpen}
      tag='div'
      subtitle={!hasEmptySubheading
        ? <ContentstackJsonRte json={header?.subheading?.json} excludeOuterTags />
        : null}
    >
      {!hasEmptyImage && (
        <ContentstackSimpleImage data={header?.image ?? {}} className='modal-title' />
      )}

      {!hasEmptyHeading && (
        <h1 className={headingClassNames}>
          <ContentstackJsonRte json={header?.heading?.json} excludeOuterTags />
        </h1>
      )}
    </ModalHeader>
  )
}

export {
  ContentstackModalHeader
}
