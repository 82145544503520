import { isStringEmpty } from '@stuller/shared/util/core'
import { MetaDescription, MetaImage, Title } from '@stuller/stullercom/feat/layout'
import { type SeoInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useMemo, type ReactElement } from 'react'

interface ContentstackSeoProps {
  data?: SeoInfoFragment | null
  pageMetaDescription?: string | null
  pageTitle?: string | null
}

/**
 * Returns string value based on logic found on the following Confluence doc
 * @link https://stullerinc.atlassian.net/wiki/spaces/WEB/pages/3142811663/Stuller.com+Page+Metadata+SEO+Breadcrumbs
 */
function getMetaValue (
  isShowcase: boolean,
  cmsDefault?: string | null,
  cmsShowcase?: string | null,
  pageDescriptionOrTitle?: string | null
): string | undefined {
  if (isShowcase && !isStringEmpty(cmsShowcase)) {
    return cmsShowcase
  } else if (!isStringEmpty(cmsDefault)) {
    return cmsDefault
  } else if (!isStringEmpty(pageDescriptionOrTitle)) {
    return pageDescriptionOrTitle
  }
}

/**
 * Metadata includes information about a page that is used by search engines to understand the content and purpose.
 */
function ContentstackSeo ({ data, pageMetaDescription, pageTitle }: ContentstackSeoProps): ReactElement {
  const { default: cmsDefault, showcase: cmsShowcase } = data ?? {}
  const { isShowcase } = useAuth()

  const { title, description, image }: { title?: string, description?: string, image?: string } = useMemo(() => {
    return {
      title: getMetaValue(isShowcase, cmsDefault?.title, cmsShowcase?.title, pageTitle),
      description: getMetaValue(isShowcase, cmsDefault?.description, cmsShowcase?.description, pageMetaDescription),
      image: getMetaValue(isShowcase, cmsDefault?.image, cmsShowcase?.image)
    }
  }, [isShowcase, cmsDefault, cmsShowcase, pageTitle, pageMetaDescription])

  return (
    <>
      {!isStringEmpty(title) && <Title>{title}</Title>}
      {!isStringEmpty(description) && <MetaDescription>{description}</MetaDescription>}
      {!isStringEmpty(image) && <MetaImage>{image}</MetaImage>}
    </>
  )
}

export {
  ContentstackSeo
}
