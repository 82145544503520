import { type ReactElement } from 'react'
import { type VerticalMediaBoxBody } from './types'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import clsx from 'clsx'
import { isArrayEmpty } from '@stuller/shared/util/core'

interface ContentstackVerticalMediaBoxBodyProps {
  data: VerticalMediaBoxBody
  alignmentClass: string
}

/**
 * Renders the body text and buttons inside of a ContentstackVerticalMediaBox component
 * @memberof ContentstackVerticalMediaGoxGroup
 */
function ContentstackVerticalMediaBoxBody ({ data, alignmentClass }: ContentstackVerticalMediaBoxBodyProps): ReactElement | null {
  const { rich_text: richText, buttons } = data ?? {}

  const isRichTextEmpty = isRteEmpty(richText?.json)
  const isButtonsArrayEmpty = isArrayEmpty(buttons)

  if (isRichTextEmpty && isButtonsArrayEmpty) {
    return null
  }

  const bodyClasses = clsx('row gy-3', alignmentClass)
  const buttonClasses = clsx(
    'row gx-4 gy-3',
    alignmentClass === 'text-center' ? 'justify-content-center' : 'justify-content-start'
  )

  return (
    <div className='col-12'>
      <div className={bodyClasses}>
        {!isRichTextEmpty && (
          <div className='col-12 mb-n3'>
            <ContentstackJsonRte json={richText?.json ?? []} />
          </div>)}
        {!isButtonsArrayEmpty && (
          <div className='col-12'>
            <div className={buttonClasses}>
              {buttons?.map((button, i) => !isButtonEmpty(button) && (
                <div
                  className='col-auto'
                  key={i}
                >
                  <ContentstackButton
                    data={button}
                    data-gtm-click-subsection2={`${i > 0 ? 'secondary' : 'primary'}-cta`}
                  />
                </div>
              ))}
            </div>
          </div>)}
      </div>
    </div>
  )
}

export {
  ContentstackVerticalMediaBoxBody
}
