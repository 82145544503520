import { type ReactElement } from 'react'
import { type FormstackFormInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { FormstackForm } from '@stuller/stullercom/feat/formstack'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackFormstackFormProps {
  data?: FormstackFormInfoFragment | null
}

/**
 * Renders a Formstack form by name.
 */
function ContentstackFormstackForm ({ data }: ContentstackFormstackFormProps): ReactElement | null {
  const {
    form_name: formName
  } = data ?? {}

  if (isStringEmpty(formName)) {
    return null
  }

  return (
    <FormstackForm name={formName} />
  )
}

export {
  ContentstackFormstackForm
}
