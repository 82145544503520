import { ContentstackPublicationCarouselItem } from './ContentstackPublicationCarouselItem'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { MultiItemCarousel, MultiItemCarouselControl, MultiItemCarouselIndicators, MultiItemCarouselInner, MultiItemCarouselItem, useMultiItemCarousel } from '@stuller/stullercom/ui'
import { type PublicationCarouselInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'
import { isArrayEmpty } from '@stuller/shared/util/core'

interface ContentstackPublicationCarouselProps {
  data?: PublicationCarouselInfoFragment | null
}

/**
 * Publications displayed in a carousel
 */
function ContentstackPublicationCarousel ({ data }: ContentstackPublicationCarouselProps): ReactElement | null {
  const {
    appearance,
    publications,
    section_header: sectionHeader,
    targetingConnection,
    title
  } = data ?? {}
  const multiItemCarouselProps = useMultiItemCarousel()
  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', 'y', appearance?.spacing_y)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  const isGroupEmpty = isArrayEmpty(publications)
  if (!showContent || isGroupEmpty) {
    return null
  }

  const sectionClassNames = clsx(
    'mw-xxxl m-auto',
    background.className
  )

  const containerClassNames = clsx(
    'container-xxxl mw-xxl py-6 py-md-8 px-4',
    spacing?.className,
    appearance?.text_color === 'body' ? 'text-body' : 'text-white links-white'
  )

  return (
    <section className={sectionClassNames} id={contentstackTitleToHtmlId(title)} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
      <div className={containerClassNames}>
        <div className='row gy-5'>
          <ContentstackSectionHeader data={sectionHeader} />
          <MultiItemCarousel
            {...multiItemCarouselProps}
          >
            <MultiItemCarouselInner gap='2rem' className='pb-4 pb-md-0' data-gtm-click-section='brochure-catalog'>
              {publications?.map((publication, index) => publication != null && (
                <MultiItemCarouselItem key={publication.title}>
                  <ContentstackPublicationCarouselItem data={publication} index={index} />
                </MultiItemCarouselItem>
              ))}
            </MultiItemCarouselInner>
            <MultiItemCarouselControl direction='prev' />
            <MultiItemCarouselControl direction='next' />
            <MultiItemCarouselIndicators />
          </MultiItemCarousel>
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackPublicationCarousel
}
