import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isStringEmpty } from '@stuller/shared/util/core'
import Link from 'next/link'
import { type ReactNode } from 'react'

interface ContentstackShoppableDotTextContentProps {
  url?: string
  text?: string
}

function ContentstackShoppableDotTextContent ({ url, text }: ContentstackShoppableDotTextContentProps): ReactNode | null {
  if (isStringEmpty(url) || isStringEmpty(text)) {
    return null
  }

  return (
    <div className='row align-items-center p-3 fs-5'>
      <div className='col'>
        <Link
          data-gtm-click-subsection2='shoppable-products'
          data-gtm-click-text={text}
          href={url}
          className='stretched-link text-decoration-none fw-normal text-body d-block'
        >
          {text}
        </Link>
      </div>
      <div className='col-auto'>
        <FontAwesomeIcon icon={fas.faCaretRight} size='2x' className='text-cyan-300' />
      </div>
    </div>
  )
}

export { ContentstackShoppableDotTextContent }
