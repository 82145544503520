import { Card, CardBody } from '@stuller/stullercom/ui'
import { ContentstackCardBody } from './ContentstackCardBody'
import { ContentstackCardHeader } from './ContentstackCardHeader'
import { ContentstackCardMedia } from './ContentstackCardMedia'
import { isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { isStringEmpty } from '@stuller/shared/util/core'
import { type CardGroupCard } from './types'
import { type CSSProperties, type ReactElement } from 'react'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackCardProps {
  card?: CardGroupCard
  /**
   * Determines left or center aligned text
   */
  alignmentClass: string
  style?: CSSProperties
  index?: number
}

/**
 * Individual Card rendered inside <ContentstackCardGroup /> component instances
 * @memberof ContentstackCardGroup
 */
function ContentstackCard ({ card, alignmentClass, style, index }: ContentstackCardProps): ReactElement | null {
  const {
    title,
    header,
    body,
    media,
    targetingConnection
  } = card ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const isHeaderEmpty = isRteEmpty(header?.heading?.json)
  const isBodyEmpty = isRteEmpty(body?.rich_text?.json)
  const isFullImageEmpty = isSimpleImageEmpty(media?.full_image)
  const isPortraitImageEmpty = isSimpleImageEmpty(media?.portrait_image)
  const isIconEmpty = isStringEmpty(media?.icon)
  const isMediaEmpty = isFullImageEmpty && isPortraitImageEmpty && isIconEmpty

  if (isHeaderEmpty && isBodyEmpty && isMediaEmpty) {
    return null
  }

  const cardBodyClassNames = clsx(
    'px-4 pb-6',
    isMediaEmpty ? 'pt-6' : 'pt-0'
  )
  const cardClassName = clsx(
    'h-100',
    alignmentClass
  )

  return (
    <Card
      className={cardClassName}
      style={style}
      data-gtm-click-section='card'
      data-gtm-click-subsection1={title}
      data-gtm-click-subsection1-index={index}
    >
      <div className='row gy-4'>
        <ContentstackCardMedia data={media} />
        {(!isHeaderEmpty || !isBodyEmpty) && (
          <div className='col-12'>
            <CardBody className={cardBodyClassNames}>
              <ContentstackCardHeader
                alignmentClass={alignmentClass}
                data={header}
              />
              <ContentstackCardBody
                alignmentClass={alignmentClass}
                data={body}
              />
            </CardBody>
          </div>
        )}
      </div>
    </Card>
  )
}

export {
  ContentstackCard
}
