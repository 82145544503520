import { type ReactElement } from 'react'
import { type ShoppableFeatureInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ProductBadge, type ProductBadgeType } from '@stuller/stullercom/ui'
import clsx from 'clsx'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackShoppableFeatureHeaderProps {
  data: ShoppableFeatureInfoFragment['header']
  textPosition?: string
  headingFontSize?: string | null
}

/**
 * Renders the badge, heading, and subheading inside of a ContentstackShoppableFeature component
 * @memberof ContentstackTopic
 */
function ContentstackShoppableFeatureHeader ({ data, textPosition, headingFontSize }: ContentstackShoppableFeatureHeaderProps): ReactElement | null {
  const {
    heading,
    heading_image: headingImage,
    badge
  } = data ?? {}
  const textPositionClasses = clsx(textPosition === 'center' && 'text-center')
  const headingEmpty = isRteEmpty(heading?.json)
  const headingImageEmpty = isSimpleImageEmpty(headingImage)
  const isBadgeEmpty = isStringEmpty(badge)
  const headingClassNames = clsx(
    'mb-2 h1',
    headingFontSize ?? ''
  )

  if (headingEmpty && headingImageEmpty && !isBadgeEmpty) {
    return null
  }

  return (
    <div className={textPositionClasses}>
      {!headingImageEmpty && <div className='mb-3'><ContentstackSimpleImage data={headingImage} /></div>}
      {!isBadgeEmpty && <div className='mb-3'><ProductBadge type={badge as ProductBadgeType} /></div>}
      {!headingEmpty && <h2 className={headingClassNames}><ContentstackJsonRte excludeOuterTags json={heading?.json} /></h2>}
    </div>
  )
}

export {
  ContentstackShoppableFeatureHeader
}
