import { type ContentstackYoutubeVideoProps } from './ContentstackYoutubeVideo'
import { type ReactElement } from 'react'

/**
 * Base div/iframe of the video component
 */
function ContentstackYoutubeVideoBase ({
  data,
  maxWidth = '700px',
  autoFocus = false
}: ContentstackYoutubeVideoProps): ReactElement | null {
  const {
    accessibility_title: title,
    video
  } = data ?? {}

  return (
    <div className='bg-black col-12 rounded-3'>
      <iframe
        allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        autoFocus={autoFocus}
        className='d-block w-100 rounded-3'
        loading='lazy'
        referrerPolicy='strict-origin-when-cross-origin'
        src={`https://www.youtube.com/embed/${video?.id}?mute=1`}
        style={{ aspectRatio: '16 / 9', maxWidth }}
        title={title ?? ''}
      />
    </div>
  )
}

export {
  ContentstackYoutubeVideoBase
}
