import { type ReactElement } from 'react'
import { ContentstackAccordion } from '../contentstack-accordion/ContentstackAccordion'
import { ContentstackBlogCardGroup } from '../contentstack-blog-card-group/ContentstackBlogCardGroup'
import { ContentstackCallUsFeature } from '../contentstack-call-us-feature/ContentstackCallUsFeature'
import { ContentstackCardGroup } from '../contentstack-card-group/ContentstackCardGroup'
import { ContentstackCardWithBackgroundGroup } from '../contentstack-card-with-background-group/ContentstackCardWithBackgroundGroup'
import { ContentstackDirectoryCardGroup } from '../contentstack-directory-card-group/ContentstackDirectoryCardGroup'
import { ContentstackEscapeHatch } from '../contentstack-escape-hatch/ContentstackEscapeHatch'
import { ContentstackExpandableImageGroup } from '../contentstack-expandable-image-group/ContentstackExpandableImageGroup'
import { ContentstackHighlightFeature } from '../contentstack-highlight-feature/ContentstackHighlightFeature'
import { ContentstackLargeShoppingBuckets } from '../contentstack-large-shopping-buckets/ContentstackLargeShoppingBuckets'
import { ContentstackLegacyCmsContent } from '../contentstack-legacy-cms-content/ContentstackLegacyCmsContent'
import { ContentstackMediaBoxGroup } from '../contentstack-media-box-group/ContentstackMediaBoxGroup'
import { ContentstackMediaCalloutGroup } from '../contentstack-media-callout-group/ContentstackMediaCalloutGroup'
import { ContentstackPopOutFeature } from '../contentstack-pop-out-feature/ContentstackPopOutFeature'
import { ContentstackProductCardCarousel } from '../contentstack-product-card-carousel/ContentstackProductCardCarousel'
import { ContentstackPublicationCarousel } from '../contentstack-publication-carousel/ContentstackPublicationCarousel'
import { ContentstackRow } from '../contentstack-row/ContentstackRow'
import { ContentstackSearchBySerialNumber } from '../contentstack-search-by-serial-number/ContentstackSearchBySerialNumber'
import { ContentstackShoppableFeature } from '../contentstack-shoppable-feature/ContentstackShoppableFeature'
import { ContentstackSmallShoppingBuckets } from '../contentstack-small-shopping-buckets/ContentstackSmallShoppingBuckets'
import { ContentstackSocialIconsFeature } from '../contentstack-social-icons-feature/ContentstackSocialIconsFeature'
import { ContentstackSplitTopic } from '../contentstack-split-topic/ContentstackSplitTopic'
import { ContentstackStatisticsCardGroup } from '../contentstack-statistics-card-group/ContentstackStatisticsCardGroup'
import { ContentstackStoneShapeIcons } from '../contentstack-stone-shape-icons/ContentstackStoneShapeIcons'
import { ContentstackTestimonials } from '../contentstack-testimonials/ContentstackTestimonials'
import { ContentstackTopic } from '../contentstack-topic/ContentstackTopic'
import { ContentstackVerticalBackgroundCardGroup } from '../contentstack-vertical-background-card-group/ContentstackVerticalBackgroundCardGroup'
import { ContentstackVerticalMediaBoxGroup } from '../contentstack-vertical-media-box-group/ContentstackVerticalMediaBoxGroup'
import { ContentstackVideoCarousel } from '../contentstack-video-carousel/ContentstackVideoCarousel'

interface ContentstackModularBlocksProps {
  modularBlocks: any
  prefix: string
}

/**
 * ContentstackModularBlocks component renders a list of modular blocks based on the provided page contents.
 * It dynamically selects and renders the appropriate component for each block type.
 */
function ContentstackModularBlocks ({ modularBlocks, prefix }: ContentstackModularBlocksProps): ReactElement | null {
  return (
    <>
      {
        modularBlocks?.map((component: any, key: number) => { // eslint-disable-line complexity
          switch (component.__typename) {
            case `${prefix}Accordion`:
              return <ContentstackAccordion key={key} data={component.accordion?.accordionConnection?.edges?.[0]?.node} />
            case `${prefix}BackgroundCardGroup`:
              return <ContentstackCardWithBackgroundGroup key={key} data={component.background_card_group?.background_card_groupConnection?.edges?.[0]?.node} />
            case `${prefix}CardWithBackgroundGroup`:
              return <ContentstackCardWithBackgroundGroup key={key} data={component.card_with_background_group?.card_with_background_groupConnection?.edges?.[0]?.node} />
            case `${prefix}BlogCardGroup`:
              return <ContentstackBlogCardGroup key={key} data={component.blog_card_group?.blog_card_groupConnection?.edges?.[0]?.node} />
            case `${prefix}CallUsFeature`:
              return <ContentstackCallUsFeature key={key} data={component.call_us_feature?.call_us_featureConnection?.edges?.[0]?.node} />
            case `${prefix}CardGroup`:
              return <ContentstackCardGroup key={key} data={component.card_group?.card_groupConnection?.edges?.[0]?.node} />
            case `${prefix}DirectoryCardGroup`:
              return <ContentstackDirectoryCardGroup key={key} data={component.directory_card_group?.directory_card_groupConnection?.edges?.[0]?.node} />
            case `${prefix}EscapeHatch`:
              return <ContentstackEscapeHatch key={key} data={component.escape_hatch?.escape_hatchConnection?.edges?.[0]?.node} />
            case `${prefix}ExpandableImageGroup`:
              return <ContentstackExpandableImageGroup key={key} data={component.expandable_image_group?.expandable_image_groupConnection?.edges?.[0]?.node} />
            case `${prefix}HighlightFeature`:
              return <ContentstackHighlightFeature key={key} data={component.highlight_feature?.highlight_featureConnection?.edges?.[0]?.node} />
            case `${prefix}LargeShoppingBuckets`:
              return <ContentstackLargeShoppingBuckets key={key} data={component.large_shopping_buckets?.large_shopping_bucketsConnection?.edges?.[0]?.node} />
            case `${prefix}LegacyCmsContent`:
              return <ContentstackLegacyCmsContent key={key} data={component.legacy_cms_content?.legacy_cms_contentConnection?.edges?.[0]?.node} />
            case `${prefix}MediaBoxGroup`:
              return <ContentstackMediaBoxGroup key={key} data={component.media_box_group?.media_box_groupConnection?.edges?.[0]?.node} />
            case `${prefix}MediaCalloutGroup`:
              return <ContentstackMediaCalloutGroup key={key} data={component.media_callout_group?.media_callout_groupConnection?.edges?.[0]?.node} />
            case `${prefix}PopOutFeature`:
              return <ContentstackPopOutFeature key={key} data={component.pop_out_feature?.pop_out_featureConnection?.edges?.[0]?.node} />
            case `${prefix}ProductCardCarousel`:
              return <ContentstackProductCardCarousel key={key} data={component.product_card_carousel?.product_card_carouselConnection?.edges?.[0]?.node} />
            case `${prefix}PublicationCarousel`:
              return <ContentstackPublicationCarousel key={key} data={component.publication_carousel?.publication_carouselConnection?.edges?.[0]?.node} />
            case `${prefix}Row`:
              return <ContentstackRow key={key} data={component.row?.rowConnection?.edges?.[0]?.node} />
            case `${prefix}SearchBySerialNumber`:
              return <ContentstackSearchBySerialNumber key={key} data={component.search_by_serial_number?.search_by_serial_numberConnection?.edges?.[0]?.node} />
            case `${prefix}ShoppableFeature`:
              return <ContentstackShoppableFeature key={key} data={component.shoppable_feature?.shoppable_featureConnection?.edges?.[0]?.node} />
            case `${prefix}SmallShoppingBuckets`:
              return <ContentstackSmallShoppingBuckets key={key} data={component.small_shopping_buckets?.small_shopping_bucketsConnection?.edges?.[0]?.node} />
            case `${prefix}SocialIconsFeature`:
              return <ContentstackSocialIconsFeature key={key} data={component.social_icons_feature?.social_icons_featureConnection?.edges?.[0]?.node} />
            case `${prefix}SplitTopic`:
              return <ContentstackSplitTopic key={key} data={component.split_topic?.split_topicConnection?.edges?.[0]?.node} />
            case `${prefix}StatisticsCardGroup`:
              return <ContentstackStatisticsCardGroup key={key} data={component.statistics_card_group?.statistics_card_groupConnection?.edges?.[0]?.node} />
            case `${prefix}StoneShapeIcons`:
              return <ContentstackStoneShapeIcons key={key} data={component.stone_shape_icons?.stone_shape_iconsConnection?.edges?.[0]?.node} />
            case `${prefix}Testimonials`:
              return <ContentstackTestimonials key={key} data={component.testimonials?.testimonialsConnection?.edges?.[0]?.node} />
            case `${prefix}Topic`:
              return <ContentstackTopic key={key} data={component.topic?.topicConnection?.edges?.[0]?.node} />
            case `${prefix}VerticalBackgroundCardGroup`:
              return <ContentstackVerticalBackgroundCardGroup key={key} data={component.vertical_background_card_group?.vertical_background_card_groupConnection?.edges?.[0]?.node} />
            case `${prefix}VerticalMediaBoxGroup`:
              return <ContentstackVerticalMediaBoxGroup key={key} data={component.vertical_media_box_group?.vertical_media_box_groupConnection?.edges?.[0]?.node ?? component.vertical_media_box_group?.referenceConnection?.edges?.[0]?.node} />
            case `${prefix}VideoCarousel`:
              return <ContentstackVideoCarousel key={key} data={component.video_carousel?.video_carouselConnection?.edges?.[0]?.node} />
            default:
              return null
          }
        })
      }
    </>
  )
}

export {
  ContentstackModularBlocks
}
