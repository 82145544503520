import { type CSSProperties, useState, type ReactElement } from 'react'
import { type ShoppableImageDot } from '@stuller/contentstack/util/types'
import { Popover, type ProductData } from '@stuller/stullercom/ui'
import { ContentstackShoppableDotProductContent } from './ContentstackShoppableDotProductContent'
import { ContentstackShoppableDotTextContent } from './ContentstackShoppableDotTextContent'

interface ContentstackShoppableDotPopupProps {
  data?: ShoppableImageDot
  productData?: ProductData | null
  reference?: Element | null
}

interface ContentstackShoppableDotPopupContentProps {
  data?: ShoppableImageDot | null
  productData?: ProductData | null
}

/**
 * Popup that displays information about a topic or product when
 */
function ContentstackShoppableDotPopupContent ({ data, productData }: ContentstackShoppableDotPopupContentProps): ReactElement | null {
  switch (data?.content?.type) {
    case 'TEXT':
      return (
        <ContentstackShoppableDotTextContent
          text={data?.content?.text}
          url={data?.content?.url}
        />
      )
    case 'PRODUCT':
      return (
        <ContentstackShoppableDotProductContent
          data={productData}
          productOrGroupNumber={productData?.itemNumber}
        />
      )
    case 'PRODUCT_GROUP':
      return (
        <ContentstackShoppableDotProductContent
          data={productData}
          productOrGroupNumber={productData?.groupId}
        />
      )
    default:
      return null
  }
}

function ContentstackShoppableDotPopup ({ data, reference, productData }: ContentstackShoppableDotPopupProps): ReactElement | null {
  const [open, handleOpenChange] = useState(false)
  const customPopoverStyles: CSSProperties = {
    background: 'linear-gradient(to bottom, #fff, #eee',
    minWidth: data?.content?.type !== 'TEXT' ? 370 : 250
  }

  return (
    <Popover
      style={customPopoverStyles}
      hover
      className='text-body'
      open={open}
      onOpenChange={handleOpenChange}
      reference={reference}
    >
      <ContentstackShoppableDotPopupContent data={data} productData={productData} />
    </Popover>
  )
}

export {
  ContentstackShoppableDotPopup
}
