import { type PageHeader } from './types'
import { ContentstackPageHeaderBody } from './ContentstackPageHeaderBody'
import { ContentstackPageHeaderFeaturedMedia } from './ContentstackPageHeaderFeaturedMedia'
import { ContentstackPageHeaderHeader } from './ContentstackPageHeaderHeader'
import { type ReactElement } from 'react'
import { stringToHtmlId } from '@stuller/shared/util/core'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackPageHeaderProps {
  data?: PageHeader
}

/**
 * The Header appears at the top of each page to textually and visually communicate what users can expect to encounter beneath it. It can be used to orient users as to where they are and provide introductory content about the page.
 */
function ContentstackPageHeader ({ data }: ContentstackPageHeaderProps): ReactElement | null {
  const {
    title,
    appearance,
    body,
    featured_media: featuredMedia,
    header,
    targetingConnection
  } = data ?? {}

  const {
    text_color: textColor,
    text_position: textPosition,
    button_orientation: buttonOrientation,
    heading_font_size: headingFontSize
  } = appearance ?? {}

  // background-specific classes for background images, colors, and padding
  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', 'y', appearance?.spacing_y)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || data == null) {
    return null
  }

  // background classes
  const headerClassNames = clsx(
    'flex position-relative overflow-hidden mw-xxxl m-auto',
    background.className,
    'text-' + textColor
  ) ?? ''

  // inner container classes
  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className
  )

  // text position of left or right column
  const rowPositionClassNames = clsx(
    'row hx-0 gx-md-6 gy-5',
    textPosition === 'center' && 'flex-column-reverse align-items-center',
    textPosition === 'left' && 'flex-column flex-md-row',
    textPosition === 'right' && 'flex-column-reverse flex-md-row-reverse'
  )

  // text alignment checks
  const textColumnAlignmentClassNames = clsx(
    'col my-auto',
    textPosition === 'center' && 'text-center'
  )

  return (
    <header
      className={headerClassNames}
      id={stringToHtmlId(title, 'page-')}
      data-gtm-click-section='topic'
      data-gtm-click-subsection1={title}
    >
      <div className={containerClassNames}>
        <div className={rowPositionClassNames}>
          <div className='col-md-6'>
            <div className='row h-100'>
              <div className={textColumnAlignmentClassNames}>
                <ContentstackPageHeaderHeader
                  data={header}
                  headingFontSize={headingFontSize}
                />
                <ContentstackPageHeaderBody
                  data={body}
                  buttonOrientation={buttonOrientation}
                  textPosition={textPosition}
                />
              </div>
            </div>
          </div>
          <ContentstackPageHeaderFeaturedMedia data={featuredMedia} />
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </header>
  )
}

export {
  ContentstackPageHeader
}
