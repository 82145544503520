import css from 'styled-jsx/css'
import {
  type SpacingInfoFragment
} from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'
import { useMemo } from 'react'

const breakpoints: Record<string, string> = {
  spacing_xs: variables.breakpointXs,
  spacing_sm: variables.breakpointSm,
  spacing_md: variables.breakpointMd,
  spacing_lg: variables.breakpointLg,
  spacing_xl: variables.breakpointXl,
  spacing_xxl: variables.breakpointXxl,
  spacing_xxxl: variables.breakpointXxxl
}

type Target = 'x' | 'y' | 'top' | 'end' | 'bottom' | 'start' | 'min' | 'max'

type SpacingType = 'padding' | 'margin' | 'height' | 'width'

function getSpacing (type: SpacingType, targets: Target | Target[] | null, spacing: number): string {
  if (targets == null) {
    return `${type}: ${spacing}px`
  }

  const spacingStyles: Record<string, string> = {
    x: `
        ${type}-left: ${spacing}px !important;
        ${type}-right: ${spacing}px !important;
    `,
    y: `
        ${type}-top: ${spacing}px !important;
        ${type}-bottom: ${spacing}px !important;
    `,
    top: `${type}-top: ${spacing}px !important;`,
    end: `${type}-right: ${spacing}px !important;`,
    bottom: `${type}-bottom: ${spacing}px !important;`,
    start: `${type}-left: ${spacing}px !important;`,
    min: `min-${type}: ${spacing}px !important`,
    max: `max-${type}: ${spacing}px !important`
  }

  return Array.of(targets).flat().map(target => spacingStyles[target]).join('')
}

/**
 * A hook for adding CSS for custom spacing to Contentstack components with background images
 * It takes in a spacing type, targets for where to apply spacing, and the spacing amount
 */
function useContentstackSpacing (type: SpacingType, target: Target | Target[], spacing?: SpacingInfoFragment | null): ReturnType<typeof css.resolve> | null {
  const spacingCSS = useMemo(() => {
    if (spacing == null) {
      return null
    }

    return Object.entries(spacing).map(([spaces, value]): string => {
      if (typeof value === 'number' && breakpoints[spaces] != null) {
        return `
          @media (min-width: ${breakpoints[spaces]}) {
            ${getSpacing(type, target, value)}
          }
        `
      }

      return ''
    }).join('')
  }, [spacing, target, type])

  if (spacingCSS == null) {
    return css.resolve``
  }

  return css.resolve`
    * {
      ${spacingCSS}
    }
  `
}

export {
  useContentstackSpacing
}
