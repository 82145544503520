import { type ReactElement } from 'react'
import { type LegacyCmsContentInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { CmsContent } from '@stuller/stullercom/feat/cms-content'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackLegacyCmsContentProps {
  data?: LegacyCmsContentInfoFragment | null
}

/**
 * Renders legacy CMS content by name.
 * Please don't use this unless you have to.
 */
function ContentstackLegacyCmsContent ({ data }: ContentstackLegacyCmsContentProps): ReactElement | null {
  const {
    content_container_name: contentContainerName
  } = data ?? {}

  if (isStringEmpty(contentContainerName)) {
    return null
  }

  return (
    <CmsContent
      contentContainerName={contentContainerName}
    />
  )
}

export {
  ContentstackLegacyCmsContent
}
