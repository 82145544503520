import { ContentstackShoppableFeatureBody } from './ContentstackShoppableFeatureBody'
import { ContentstackShoppableFeatureHeader } from './ContentstackShoppableFeatureHeader'
import { ContentstackShoppableResponsiveImage } from '../contentstack-shoppable-responsive-image/ContentstackShoppableResponsiveImage'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type ReactElement } from 'react'
import { type ShoppableFeatureInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackShoppableFeatureProps {
  data?: ShoppableFeatureInfoFragment | null
}

/**
 * The Shoppable Feature features an image with selectable products that are indicated with circles (referred to as “shoppable dots”)
 */
function ContentstackShoppableFeature ({ data }: ContentstackShoppableFeatureProps): ReactElement | null {
  const {
    header,
    body,
    appearance,
    shoppable_image: shoppableImage,
    targetingConnection,
    title
  } = data ?? {}

  const {
    content_position: contentPosition,
    background: backgroundImage,
    text_color: textColor
  } = appearance ?? {}

  const background = useContentstackBackground(backgroundImage)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const {
    image_xs: imageXs,
    image_sm: imageSm,
    image_md: imageMd,
    image_lg: imageLg,
    image_xl: imageXl,
    image_xxl: imageXxl,
    image_xxxl: imageXxxl
  } = shoppableImage?.image_sources ?? {}

  const hasShoppableImage = (
    !isStringEmpty(imageXs?.url as string | null | undefined) ||
    !isStringEmpty(imageSm?.url as string | null | undefined) ||
    !isStringEmpty(imageMd?.url as string | null | undefined) ||
    !isStringEmpty(imageLg?.url as string | null | undefined) ||
    !isStringEmpty(imageXl?.url as string | null | undefined) ||
    !isStringEmpty(imageXxl?.url as string | null | undefined) ||
    !isStringEmpty(imageXxxl?.url as string | null | undefined)
  )

  if (!showContent || !hasShoppableImage) {
    return null
  }

  const sectionClassNames = clsx(
    'mw-xxxl m-auto',
    background.className
  )

  const rowClassNames = clsx(
    'row align-items-center',
    contentPosition === 'center' && 'flex-column-reverse align-items-center',
    contentPosition === 'left' && 'flex-column-reverse flex-md-row',
    contentPosition === 'right' && 'flex-column-reverse flex-md-row-reverse',
    textColor === 'white' && 'text-white'
  )

  return (
    <section
      id={contentstackTitleToHtmlId(title)}
      className={sectionClassNames}
      data-gtm-click-section='topic'
      data-gtm-click-subsection1={title}
    >
      <div className='container-xxxl mw-xxxl'>
        <div className={rowClassNames}>
          <div className='col-12 col-md-6 py-8 px-6 m-auto' style={{ maxWidth: 660 }}>
            <ContentstackShoppableFeatureHeader data={header} />
            <ContentstackShoppableFeatureBody data={body} />
          </div>
          <div className='col-12 col-md-6 p-0'>
            <ContentstackShoppableResponsiveImage title={title ?? ''} data={shoppableImage} />
          </div>
        </div>
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackShoppableFeature
}
